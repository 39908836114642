html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img {
  box-sizing: content-box;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: var(--black);
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  -webkit-appearance: textfield;
  /* -moz-box-sizing: content-box;
   -webkit-box-sizing: content-box;
   box-sizing: content-box;
   */
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
input:focus,
textarea:focus {
  outline: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #9b9b9b;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #9b9b9b;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #9b9b9b;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #9b9b9b;
}
input:-o-input-placeholder,
textarea:-o-input-placeholder {
  color: #9b9b9b;
}
input:input-placeholder,
textarea:input-placeholder {
  color: #9b9b9b;
}
.button {
  display: block;
  text-decoration: none;
  text-align: center;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: opacity 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
.button.big-orange {
  width: 208px;
  height: 48px;
  border-radius: 3px;
  background-color: #e9604a;
  line-height: 48px;
  color: var(--white);
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  transition: background-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: background-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
.button.big-orange:hover {
  background-color: #ff7157;
}
.button.biger {
  width: 468px;
  height: 64px;
  line-height: 64px;
  font-size: 28px;
}
.button.add-to {
  width: auto;
  padding: 0 10px;
  height: 28px;
  border-radius: 3px;
  border: solid 1px #2da5e0;
  line-height: 26px;
  text-align: center;
  font-size: 14px;
  color: #2da5e0;
}
.button.add-to::after {
  content: ' ';
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  vertical-align: -2px;
  background: url('/images/button/ic_telegram.svg') center center no-repeat;
}
.button.add-to:hover {
  color: var(--white);
  background-color: #2da5e0;
}
.button.add-to:hover::after {
  background-image: url('/images/button/ic_telegram_.svg');
}
.button.mobile-menu {
  display: none;
  height: 48px;
  width: 48px;
  background: url('/images/button/ic_menu.svg') center center no-repeat;
}
.button.close {
  height: 48px;
  width: 48px;
  background: url('../images/button/ic_close.svg') center center no-repeat;
}
.button.blue {
  width: 201px;
  height: 48px;
  line-height: 48px;
  border-radius: 3px;
  background-color: #2da5e0;
  font-size: 20px;
  color: var(--white);
  text-transform: uppercase;
  transition: background-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: background-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
.button.blue:hover {
  background-color: #3db5f0;
}
.button.dark {
  display: inline-block;
  width: 158px;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: #053748;
  font-size: 16px;
  color: var(--white);
  text-transform: uppercase;
  transition: background-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: background-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  padding: 0;
}
.button.dark:hover {
  background-color: #154758;
}
.button.dark.disabled {
  background-color: #053748 !important;
}
.button.disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}
.button.continue-reading {
  width: 218px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  color: var(--white) !important;
}
.button.continue-reading::after {
  content: ' >';
  font-size: 16px;
  display: inline-block;
  font-weight: 600;
  transform: scale(0.8, 1.7);
  margin-left: 5px;
}
.button.blog {
  color: var(--white) !important;
  width: 220px;
  height: 48px;
  font-size: 16px;
  text-decoration: none !important;
}
.button.head {
  width: 154px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}
.button.bordered-orange {
  width: 226px;
  height: 48px;
  line-height: 44px;
  border-radius: 3px;
  background-color: transparent;
  border: solid 2px #e9604a;
  font-size: 20px;
  color: var(--white);
}
.button.db-sq {
  width: 24px;
  height: 24px;
  background-position: center center;
  background-repeat: no-repeat;
}
.button.db-orange {
  width: 202px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  background-color: #e9604a;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--white);
  text-decoration: none;
}
.button.db-orange:hover {
  background-color: #ff7157;
  text-decoration: none;
}
.button.db-orange[disabled],
.button.db-orange.disabled {
  pointer-events: none;
  cursor: auto;
  color: var(--white);
  background-color: #e6e5e4;
}
.button.db-border {
  width: 202px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
  background-color: var(--white);
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--black);
  text-decoration: none;
}
.button.db-border:hover {
  border: solid 1px #999999;
}
.button.db-border[disabled],
.button.db-border.disabled {
  pointer-events: none;
  cursor: auto;
  color: rgba(0, 0, 0, 0.3);
}
.button.db-link {
  width: auto;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #807e7e;
  text-decoration: none;
}
.button.db-link:hover {
  color: var(--black);
}
.button.db-link[disabled],
.button.db-link.disabled {
  pointer-events: none;
  cursor: auto;
}
.button.db-blue,
a.button.db-blue {
  width: 361px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background-color: #448af1;
  font-size: 16px;
  color: var(--white);
  text-align: center;
}
.button.db-blue:hover,
a.button.db-blue:hover {
  background-color: #549aff;
}
.button.db-dark {
  width: 320px;
  height: 48px;
  line-height: 48px;
  border-radius: 3px;
  background-color: #053748;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
}
.button.db-dark:hover {
  background-color: #184a5b;
  text-decoration: none;
}
.button.db-white {
  display: inline-block;
  vertical-align: top;
  width: auto;
  height: 44px;
  line-height: 42px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 0 15px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: var(--black);
  text-decoration: none;
  text-transform: none;
}
.button.db-white:hover {
  border: solid 1px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
div.alert {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  left: -99999px;
  width: auto;
  height: 34px;
  background-color: red;
  margin-top: -34px;
  transition: margin-top 200ms 0ms;
  z-index: 9999;
}
div.alert .text {
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  color: var(--white);
  padding: 0 12px;
  max-width: inherit;
  min-width: inherit;
}
div.alert .close.button {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 24px;
  height: 24px;
  background: url(../images/wigets/ic-close-white.svg) no-repeat center center;
  max-width: inherit;
  min-width: inherit;
}
div.alert.red {
  background-color: #ed7f6e;
}
div.alert.blue {
  background-color: #69a1f3;
}
div.red-gradient {
  margin-top: -54px;
  height: 54px;
  background-image: linear-gradient(272deg, #e66900, #e9604a);
  box-shadow: 0 2px 4px 0 var(--grey);
  min-width: 570px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
div.red-gradient .logo {
  background: url('../images/wigets/avatar_chatbot_white.svg') center center no-repeat;
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  min-width: 16px;
  display: inline-block;
  left: 29px;
  top: 50%;
  transform: translateY(-50%);
}
div.red-gradient .text {
  font-size: 14px;
  line-height: 18px;
  padding: 0px 12px 0 53px;
  display: inline-block;
  width: auto;
  flex-grow: 1;
  height: auto;
}
div.red-gradient .transparent-rounded {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: var(--white);
  text-transform: uppercase;
  border-radius: 7px;
  border: solid 1px var(--white);
  min-width: 100px;
  height: 34px;
  line-height: 32px;
  padding: 0 24px;
  text-align: center;
  width: auto;
  margin: 10px 29px 10px 0;
  flex-shrink: 0;
}
div.red-gradient.hidden.act {
  left: -99999px !important;
  right: auto !important;
}
div.fuckups {
  margin-top: -36px;
  height: 36px;
  background-color: #f5f5f5;
  min-width: 570px;
}
div.fuckups .y-round {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #f7ba3c;
  border-radius: 12px;
  vertical-align: -1px;
  margin-right: 4px;
}
div.fuckups .text {
  font-size: 14px;
  display: inline-block;
  color: #3c3f40;
  height: auto;
  text-align: center;
  line-height: 36px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 30px 0 12px;
}
div.fuckups .text a {
  color: #448af1;
  text-decoration: none;
}
div.fuckups .clickable-notification {
  cursor: pointer;
}
div.fuckups .close-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  background: url(../images/wigets/ic_cross_grey.svg) no-repeat center center;
  min-width: 24px;
  cursor: pointer;
}
div.fuckups .checkout p {
  font-size: 14px;
  text-align: left;
  color: #767676;
  margin: 0;
  line-height: 36px;
  padding: 0 16px 0 24px;
  display: inline-block;
  vertical-align: middle;
}
div.fuckups .checkout p .icon-me {
  background: url('../images/wigets/fmessenger.svg') no-repeat center center;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
}
div.fuckups .checkout .white-rounded {
  width: 100px;
  height: 28px;
  line-height: 26px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: var(--black);
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0 4px 0 0;
}
div.fuckups .checkout .white-rounded.disabled {
  pointer-events: none;
  opacity: 0.4;
}
div.fuckups .checkout .plugin-box {
  position: absolute;
  background-color: red;
  top: 0;
  left: 0;
  width: 180px;
  height: 100px;
  pointer-events: none;
  opacity: 0;
}
div.alert.act {
  margin-top: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 64px;
  transition: margin-top 200ms 0ms;
}
div.alert.act.red-gradient {
  left: 0;
  top: 0;
  position: relative;
  flex: 0 0 auto;
}
div.alert.act.fuckups {
  left: 0;
  top: 0;
  position: relative;
  flex: 0 0 auto;
}
@media (max-width: 1024px) {
  div.alert.act {
    left: 72px;
  }
}
body > .popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: var(--zindex-modal);
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
  overflow: hidden !important;
  opacity: 0;
  transition: opacity 100ms 0ms;
  -webkit-transition: opacity 100ms 0ms;
}
body > .popup * {
  max-width: inherit;
  min-width: inherit;
}
body > .popup .back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(37, 47, 51, 0.8);
}
body > .popup .scroll-box {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: var(--zindex-modal);
}
body > .popup .window {
  overflow: hidden;
  position: relative;
  z-index: var(--zindex-modal);
  width: 400px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.12);
  padding: 32px 30px 100px;
  margin: auto;
  top: auto !important;
  left: auto !important;
}
body > .popup .window.window-crop {
  padding: 0 0 68px;
  width: 480px;
}
body > .popup .window.window-crop .title {
  height: 52px;
  padding-top: 16px;
  padding-left: 24px;
  font-size: 16px;
  line-height: 1.5;
}
body > .popup .window.window-crop .content {
  position: relative;
  overflow: hidden;
  height: 270px;
}
body > .popup .window.window-crop .content img {
  width: 480px;
}
body > .popup .window.window-payments {
  width: 640px;
  height: 434px;
  padding: 24px 24px 16px 24px;
}
body > .popup .window.window-user-filter-permission {
  width: 560px;
  padding: 32px 24px 84px 24px;
}
body > .popup .window.window-user-filter-permission .content .title {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: var(--black);
  margin-bottom: 12px;
}
body > .popup .window.window-user-filter-permission .content .description {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  color: #767676;
}
body > .popup .window.window-user-filter-permission .content .description .permission-link {
  color: #448af1;
  cursor: pointer;
  text-decoration: none;
}
body > .popup .window.window-user-filter-permission .content .description .ico-var {
  background-color: #9e80bd;
  display: inline-block;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  border-radius: 11px;
  padding: 0 8px;
  color: var(--white);
  font-size: 14px;
}
body > .popup .window.window-user-filter-permission .content .description .ico-var::before {
  content: '{{';
  font-size: 14px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0e0e0;
}
body > .popup .window.window-user-filter-permission .content .description .ico-var::after {
  content: '}}';
  font-size: 14px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0e0e0;
}
body > .popup .window.window-user-filter-permission .button-ver2 {
  display: inline-block;
  height: 36px;
  line-height: 36px;
}
body > .popup .window.window-user-filter-permission .button-ver2.orange-rounded {
  margin-left: 22px;
}
body > .popup .window.window-user-filter-permission .button-ver2.white-rounded {
  color: #053748;
}
body > .popup .window.window-invite {
  width: 640px;
  padding: 24px 24px 93px 24px;
}
body > .popup .window.window-invite .title {
  margin: 0 0 16px;
}
body > .popup .window.window-invite .modal-description {
  margin: 0 0 12px;
}
body > .popup .window.window-invite .footer {
  padding: 0 24px;
}
body > .popup .window.window-invite .footer .button.b-false {
  border-radius: 7px;
}
body > .popup .window.window-invite .input-box {
  white-space: nowrap;
}
body > .popup .window.window-invite .selectInput {
  display: inline-block;
  width: 470px;
  height: 36px;
  line-height: 34px;
  border-radius: 7px;
  background-color: #fafafa;
  border: solid 1px rgba(0, 0, 0, 0.09);
  font-size: 16px;
  font-weight: 400;
  padding: 0 10px;
  white-space: nowrap;
  margin-right: 16px;
}
body > .popup .window.window-invite .button-ver2.white.b-copy-invite {
  border-radius: 7px;
  padding: 0 24px 0 18px;
  vertical-align: top;
}
body > .popup .window.window-invite .button-ver2.white.b-copy-invite:before {
  background: url('../images/wigets/ic_clipboard_small.svg') center center no-repeat;
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.3;
  margin-right: 8px;
}
body > .popup .window.window-invite .button-ver2.white.b-copy-invite:hover:before {
  opacity: 1;
}
body > .popup .window.window-invite .tooltip-copied {
  animation: tooltip 1240ms cubic-bezier(0.2, 0.7, 0.5, 1);
  -webkit-animation: tooltip 1240ms cubic-bezier(0.2, 0.7, 0.5, 1);
  opacity: 0;
  padding: 0;
}
body > .popup .window.window-invite .tooltip-copied .tooltip-inner {
  background-color: var(--green);
  min-width: 108px;
  height: 20px;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: var(--white);
  border-radius: 2px;
  line-height: 16px;
}
body > .popup .window.window-invite .tooltip-copied .tooltip-arrow {
  border-top-color: var(--green);
  bottom: -5px;
}
@keyframes tooltip {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
    margin: -8px 0;
  }
  90% {
    opacity: 1;
    margin: -8px 0;
  }
  100% {
    opacity: 0;
    margin: -16px 0;
  }
}
body > .popup .window.window-block-link {
  width: 554px;
  padding: 24px;
  min-height: 166px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
body > .popup .window.window-block-link .content {
  transition: height 0.1s cubic-bezier(0.2, 0.7, 0.5, 1), margin 0.2s cubic-bezier(0.2, 0.7, 0.5, 1);
  height: 117px;
}
body > .popup .window.window-block-link .title {
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: 400 !important;
}
body > .popup .window.window-block-link .title .art-switcher {
  display: inline-block;
  vertical-align: middle;
  margin-left: 16px;
}
body > .popup .window.window-block-link .modal-description {
  margin: 0;
  color: #767676;
}
body > .popup .window.window-block-link .modal-description .ref-mask {
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  color: var(--white);
  border-radius: 8px;
  background-color: #9e80bd;
  padding: 0 3px 1px 3px;
  display: inline-block;
}
body > .popup .window.window-block-link .modal-description .ref-mask:before,
body > .popup .window.window-block-link .modal-description .ref-mask:after {
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  color: #e0e0e0;
  vertical-align: top;
}
body > .popup .window.window-block-link .modal-description .ref-mask:before {
  content: '{{ ';
}
body > .popup .window.window-block-link .modal-description .ref-mask:after {
  content: ' }}';
}
body > .popup .window.window-block-link .footer {
  padding: 0 24px;
}
body > .popup .window.window-block-link .footer .button.b-false {
  border-radius: 7px;
}
body > .popup .window.window-block-link .footer .button-ver2.white.b-copy-invite {
  border-radius: 7px;
  padding: 0 12px;
  float: left;
  margin-top: 16px;
}
body > .popup .window.window-block-link .footer .button-ver2.white.b-copy-invite:before {
  background: url('../images/wigets/ic_clipboard_small.svg') center center no-repeat;
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.3;
  margin-right: 8px;
}
body > .popup .window.window-block-link .footer .button-ver2.white.b-copy-invite:hover:before {
  opacity: 1;
}
body > .popup .window.window-block-link .footer .button-ver2.white.b-copy-invite.disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}
body > .popup .window.window-block-link .footer .tooltip-copied {
  animation: tooltip 1240ms cubic-bezier(0.2, 0.7, 0.5, 1);
  -webkit-animation: tooltip 1240ms cubic-bezier(0.2, 0.7, 0.5, 1);
  opacity: 0;
  padding: 0;
}
body > .popup .window.window-block-link .footer .tooltip-copied .tooltip-inner {
  background-color: var(--green);
  min-width: 108px;
  height: 20px;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: var(--white);
  border-radius: 2px;
  line-height: 16px;
}
body > .popup .window.window-block-link .footer .tooltip-copied .tooltip-arrow {
  border-top-color: var(--green);
  bottom: -5px;
}
body > .popup .window.window-block-link .content.block-link-on {
  margin-bottom: 61px;
  height: 177px;
}
body > .popup .window.window-block-link .content.block-link-on .modal-description {
  margin-bottom: 24px;
}
body > .popup .window.window-block-link .content.block-link-on .modal-block-link {
  display: flex;
}
body > .popup .window.window-block-link .content.block-link-on .modal-block-link .link-to-block {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  align-self: center;
  -webkit-align-self: center;
  white-space: nowrap;
}
body > .popup .window.window-block-link .content.block-link-on .modal-block-link .selectInput {
  display: inline-block;
  height: 36px;
  border-radius: 7px;
  background-color: #fafafa;
  border: solid 1px rgba(0, 0, 0, 0.09);
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  font-weight: 400;
  padding: 0 10px;
  white-space: nowrap;
  margin-left: 6px;
  min-width: 100px;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}
body > .popup .window.window-block-link .input-to-copy {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: -99999px;
}
body > .popup .window.window-block-link .tooltip.referral-error .tooltip-inner {
  height: auto;
  max-width: 250px;
}
body > .popup .window .content {
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
}
body > .popup .window .content p {
  padding: 0;
  margin: 0 0 24px;
}
body > .popup .window .content p .details {
  margin-top: 24px;
}
body > .popup .window .content p b {
  font-weight: 600;
}
body > .popup .window .content p i {
  font-weight: 300;
  font-style: normal;
}
body > .popup .window .content p.title {
  font-weight: bold;
}
body > .popup .window .content p.gray {
  color: #807e7e;
}
body > .popup .window .content p.mini {
  font-size: 14px;
  line-height: 1.4;
  margin-top: -8px !important;
}
body > .popup .window .content p .red {
  color: #ea6550;
}
body > .popup .window .content p label {
  font-size: 16px;
  line-height: 1.5;
  color: #807e7e;
}
body > .popup .window .content p label input {
  width: 100%;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  font-size: 16px;
  padding: 0 10px;
  margin: 12px 0 0;
  color: var(--black);
}
body > .popup .window .content p:last-child {
  margin: 0;
}
body > .popup .window .content .selector {
  margin: 0 0 24px;
  width: 100%;
}
body > .popup .window .content .selector ul {
  width: 101%;
}
body > .popup .window .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 68px;
  line-height: 67px;
  border-top: 1px solid #d9d9d9;
  text-align: right;
  padding: 0 32px;
}
body > .popup .window .footer .button {
  display: inline-block;
  width: auto;
  min-width: 100px;
  padding: 0 24px;
  height: 36px;
  border-radius: 3px;
  line-height: 34px;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 16px;
}
body > .popup .window .footer .button.b-true {
  background-color: var(--white);
  border: solid 1px #d9d9d9;
}
body > .popup .window .footer .button.b-false {
  background-color: #e9604a;
  border: solid 1px #e9604a;
  color: var(--white);
}
body > .popup .window .footer .button.b-false[disabled] {
  background-color: #e6e5e4;
  border: solid 1px #e6e5e4;
  pointer-events: none;
}
body > .popup .window .footer .button.b-false.crop-btn-disabled {
  opacity: 0.5;
}
body > .popup .window .close.button {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 12px;
  right: 12px;
  background: url(../images/wigets/ic_close.svg) no-repeat center center;
  background-size: 19px 19px;
  opacity: 0.3;
  transition: opacity 60ms;
}
body > .popup .window .close.button:hover {
  opacity: 1;
}
body > .popup.readonly {
  overflow: auto;
}
body > .popup.readonly .back {
  pointer-events: none;
  position: fixed;
}
body > .popup.readonly .window {
  top: 165px;
  width: 960px;
  height: 772px;
  transform: translate(-50%, 0%) !important;
  -webkit-transform: translate(-50%, 0%) !important;
  padding: 0;
}
body > .popup.readonly .window .content {
  background-color: var(--greyLight20);
  padding: 48px;
  text-align: center;
}
body > .popup.readonly .window .content h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
  margin: 0 0 24px;
}
body > .popup.readonly .window .content p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 20px;
  padding: 0;
}
body > .popup.readonly .window .content .button {
  display: inline-block;
  padding: 0 24px;
  width: auto;
}
body > .popup.readonly .window .footer {
  text-align: center;
  padding: 32px 0 0;
  position: static;
  left: auto;
  bottom: auto;
  line-height: normal;
}
body > .popup.readonly .window .footer h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
  margin: 0 0 32px;
}
body > .popup.readonly .window .footer ul {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  text-align: center;
}
body > .popup.readonly .window .footer ul li {
  display: inline-block;
  margin: 0 6px -1px;
  padding: 0 8px 11px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 16px;
  color: var(--black);
  line-height: 1;
}
body > .popup.readonly .window .footer ul li.act {
  border-bottom: 2px solid #e9604a;
  pointer-events: none;
  cursor: auto;
}
body > .popup.readonly .window .footer .tabs-box {
  padding: 48px 173px 0;
}
body > .popup.readonly .window .footer .tabs-box .tab-item {
  display: none;
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  padding: 0 0 0 34px;
  text-align: left;
}
body > .popup.readonly .window .footer .tabs-box .tab-item.act {
  display: block;
}
body > .popup.readonly .window .footer .tabs-box .tab-item p {
  margin: 0 0 16px;
}
body > .popup.readonly .window .footer .tabs-box .tab-item p.title {
  font-weight: 600;
}
body > .popup.readonly .window .footer .tabs-box .tab-item ol {
  padding: 0;
  margin: 0 0 16px;
  counter-reset: item;
}
body > .popup.readonly .window .footer .tabs-box .tab-item ol li {
  display: block;
  position: relative;
  padding: 0;
  margin: 0 0 16px;
  counter-increment: item;
}
body > .popup.readonly .window .footer .tabs-box .tab-item ol li::before {
  content: counter(item);
  position: absolute;
  width: 24px;
  height: 24px;
  line-height: 22px;
  left: -34px;
  top: 0;
  border: solid 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  font-size: 16px;
  color: var(--black);
  text-align: center;
}
body > .popup.readonly .window .footer .tabs-box .tab-item p a,
body > .popup.readonly .window .footer .tabs-box .tab-item ol a,
body > .popup.readonly .window .footer .tabs-box .tab-item ul a {
  color: #5494f3;
  text-decoration: none;
}
body > .popup.readonly .window .footer .tabs-box .tab-item p a:hover,
body > .popup.readonly .window .footer .tabs-box .tab-item ol a:hover,
body > .popup.readonly .window .footer .tabs-box .tab-item ul a:hover {
  text-decoration: underline;
}
body > .popup.readonly .window .footer .tabs-box .tab-item > div:last-child {
  position: absolute;
  bottom: 48px;
  left: 0;
  width: 100%;
  text-align: center;
}
body > .popup.readonly .window .footer .tabs-box .tab-item > div:last-child .button {
  margin: 0 16px;
  padding: 0 30px;
  width: auto;
  min-width: 160px;
  height: 48px;
  line-height: 46px;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
}
body > .popup.readonly .window .footer .tabs-box .tab-item:nth-child(2) {
  margin: 0 -125px;
  padding: 0;
}
body > .popup.readonly .window .footer .tabs-box .tab-item:nth-child(2) .img {
  background: url('../images/pics/pic_wiz_12.png') no-repeat center center;
  background-size: cover;
  width: 468px;
  height: 116px;
  float: right;
  margin: 0 0 0 36px;
}
body > .popup.readonly .window .footer .fb-box {
  vertical-align: -7px;
}
body.show-modal-popup {
  overflow: hidden;
}
body.show-modal-popup .popup {
  width: 100%;
  height: 100%;
  opacity: 1;
}
body.header-alert-error {
  padding-top: 118px !important;
}
body.header-alert-error header > div,
body.header-alert-error section.bot-selector,
body.header-alert-error .wizard-header {
  top: 54px !important;
}
body.header-alert-error section.bot-selector div > nav {
  top: 118px !important;
}
body.header-alert-error section.bot-selector div > nav ul {
  min-height: 658px !important;
}
body.header-alert-error section.bot-selector div > nav ul span.bottom-list {
  bottom: 118px !important;
}
body.header-fuckups {
  padding-top: 100px !important;
}
body.header-fuckups header > div,
body.header-fuckups section.bot-selector,
body.header-fuckups .wizard-header {
  top: 36px !important;
}
body.header-fuckups section.bot-selector div > nav {
  top: 100px !important;
}
body.header-fuckups section.bot-selector div > nav > ul {
  min-height: 586px !important;
}
body.header-fuckups section.bot-selector div > nav > ul span.bottom-list {
  bottom: 100px !important;
}
.button.switch {
  position: relative;
  width: 52px;
  height: 24px;
  border-radius: 100px;
  font-size: 11px;
  line-height: 24px;
}
.button.switch.on {
  background-color: #448af1;
  color: var(--white);
}
.button.switch.on span.off {
  opacity: 0;
}
.button.switch.on span.on {
  opacity: 1;
}
.button.switch.off {
  background-color: var(--greyLight20);
  color: var(--black);
}
.button.switch.off span.off {
  opacity: 1;
}
.button.switch.off span.on {
  opacity: 0;
}
.button.switch span {
  display: block;
  padding: 0 8px 0 6px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: opacity 220ms, background-color 220ms;
}
.button.switch span.on {
  text-align: left;
}
.button.switch span.off {
  text-align: right;
}
.button.switch::after {
  content: ' ';
  position: absolute;
  background-color: var(--white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 2px;
  z-index: 2;
  transition: left 220ms;
}
.button.switch.on::after {
  left: 29px;
}
.button.switch.off::after {
  left: 2px;
}
.tooltip {
  position: absolute !important;
  display: block;
  z-index: var(--zindex-popover);
  font-size: 14px;
  opacity: 0;
  transition: opacity 200ms;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top {
  padding: 4px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip .tooltip-inner {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  height: 28px;
  line-height: 22px;
  min-width: 100px;
  padding: 2px 8px;
  border-radius: 4px;
  color: var(--white);
  background-color: #e9604a;
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #e9604a;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #e9604a;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #e9604a;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #e9604a;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #e9604a;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #e9604a;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #e9604a;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #e9604a;
}
.tooltip.two-rows .tooltip-inner {
  height: 46px;
}
.uib-datepicker .uib-title {
  width: 100%;
}
.uib-day button,
.uib-month button,
.uib-year button {
  min-width: 100%;
}
.uib-left,
.uib-right {
  width: 100%;
}
/*112px - left/right padding (112*2 = 224px)*/
[tooltip] {
  position: relative;
  width: 100%;
  height: 100%;
}
[tooltip][tooltip-body]:before,
[tooltip][tooltip-body]:after {
  background-color: var(--black);
  pointer-events: none;
}
[tooltip][tooltip-body]:before {
  position: absolute;
  min-width: 100px;
  border-radius: 3px;
  padding: 12px;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: var(--white);
  word-wrap: break-word;
  white-space: nowrap;
}
[tooltip][tooltip-body]:after {
  position: absolute;
  left: 50%;
  bottom: 28px;
  width: 10px;
  height: 13px;
  z-index: 99;
  transform: translateX(-50%) rotate(45deg);
}
[tooltip][tooltip-body][tooltip-position-right]:before {
  bottom: 50%;
  transform: translate(22px, 50%);
}
[tooltip][tooltip-body][tooltip-position-right]:after {
  bottom: 0;
  height: 10px;
  transform: translate(18px, -50%) rotate(45deg);
}
[tooltip]:hover {
  cursor: pointer;
}
[tooltip]:hover[tooltip-body]:before {
  content: attr(tooltip-body);
}
[tooltip]:hover[tooltip-body]:after {
  content: ' ';
}
[tooltip][tooltip-body][tooltip-show]:hover:before,
[tooltip][tooltip-body][tooltip-show]:hover:after {
  display: none;
}
[tooltip][tooltip-body][tooltip-show].no-valid:hover:before,
[tooltip][tooltip-body][tooltip-show].no-valid:hover:after {
  display: block;
}
[tooltip][tooltip-body][tooltip-show].no-valid:before {
  left: 100%;
  content: attr(tooltip-body);
}
[tooltip][tooltip-body][tooltip-show].no-valid:after {
  content: ' ';
  bottom: 50%;
  transform: translate(18px, 50%) rotate(45deg);
  left: 100%;
}
[tooltip][tooltip-danger]:before,
[tooltip][tooltip-danger]:after {
  background-color: var(--red);
}
.tooltip-sm .tooltip-inner {
  max-width: 254px;
  white-space: normal;
}
.full-around {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.icon-cf {
  display: inline-block;
  width: 24px;
  height: 25px;
  background-position: center center;
  background-repeat: no-repeat;
}
.icon-stat {
  width: 24px;
  height: 24px;
  display: none;
}
.icon-status {
  background-image: url('../images/ic_error.svg');
}
.share:before {
  background-image: url('../images/ic_call_small.svg');
}
.share.act:before {
  background-image: url('../images/ic_call_small_active.svg');
}
.buy:before {
  background-image: url('../images/ic_buy_small.svg');
}
.buy.act:before {
  background-image: url('../images/ic_buy_small_active.svg');
}
.icon.icon-disabled,
.trash-popup.icon-disabled {
  background-color: var(--greyLight20) !important;
  border-color: var(--greyLight20) !important;
}
.icon.icon-disabled:before,
.trash-popup.icon-disabled:before {
  opacity: 0.1 !important;
}
.icon:hover.icon-disabled,
.trash-popup:hover.icon-disabled {
  cursor: default !important;
}
.icon:hover.icon-disabled:before,
.trash-popup:hover.icon-disabled:before {
  opacity: 0.1 !important;
}
.icon-camera {
  background-image: url('../images/ic_image.svg');
}
.icon-nav [class^='icon-'],
.icon-nav [class*='icon-'] {
  width: 20px;
  height: 20px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
}
[class^='icon-'].button-ver2.icon-xs,
[class*='icon-'].button-ver2.icon-xs,
[class^='icon-'].icon-xs,
[class*='icon-'].icon-xs {
  width: 18px;
  height: 18px;
  background-size: contain;
  vertical-align: -5px;
  margin-right: -3px;
}
.icon-text {
  background-image: url('../images/ic_sm/ic_textcard.svg');
}
.icon-gallery {
  background-image: url('../images/ic_sm/ic_gallery-sm.svg');
}
.icon-image {
  background-image: url('../images/ic_sm/ic_imagecard.svg');
}
.icon-sequence {
  background-image: url('../images/ic_sm/ic_to-sequence.svg');
}
.icon-go-to-block {
  background-image: url('../images/ic_sm/ic-gotoblock-sm.svg');
}
.icon-quick-reply {
  background-image: url('../images/ic_sm/ic-quickreply-sm.svg');
}
.icon-typing {
  background-image: url('../images/ic_sm/ic_typing-sm.svg');
}
.icon-plus {
  background-image: url('../images/ic_sm/ic_add-sm.svg');
}
.icon_copy-group {
  width: 24px;
  height: 24px;
  background: url('../images/ic_clone_to_bot.svg') no-repeat center;
  position: relative !important;
  display: block;
  opacity: 0.3;
}
.icon_copy-group-mid {
  width: 26px;
  height: 24px;
  background: url('../images/ic-clone-to-bot-mid.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  position: relative !important;
  display: block;
  opacity: 0.3;
}
.icon__copy-rule {
  width: 24px;
  height: 24px;
  background: url('../images/ic_clone_to_bot.svg') no-repeat center;
  position: relative !important;
  display: inline-block;
  opacity: 0.3;
  line-height: 36px;
}
.icon__copy-rule:hover {
  opacity: 1;
}
.icon__copy-rule_placement {
  top: 5px;
}
.button-ver2 {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.button-ver2.icon {
  width: 24px;
  height: 24px;
  background: center center no-repeat;
  transition: opacity 60ms;
  -webkit-transition: opacity 60ms;
  opacity: 0.3;
}
.button-ver2.icon:hover {
  opacity: 0.9 !important;
}
.button-ver2.icon.trash {
  background-image: url('../images/ic_trash.svg');
}
.button-ver2.icon.trash-mid {
  background-image: url('../images/ic-trash-mid.svg');
}
.button-ver2.icon.h-drag {
  background-image: url('../images/ic_drag_hor.svg');
  cursor: ew-resize;
}
.button-ver2.icon.v-drag {
  background-image: url('../images/ic_drag_ver.svg');
  cursor: ns-resize;
}
.button-ver2.icon.v-drag-mid {
  background-image: url('../images/ic-drag-ver-mid.svg');
  cursor: ns-resize;
}
.button-ver2.icon.add-block {
  background-image: url('../images/ic_hash.svg');
}
.button-ver2.icon.add-small {
  background-image: url('../images/ic_add_small.svg');
}
.button-ver2.icon.done {
  background-image: url('../images/ic_done.svg');
}
.button-ver2.icon.close {
  background-image: url('../images/ic_close.svg');
}
.button-ver2.icon.close-small {
  background-image: url('../images/ic_cross_small.svg');
}
.button-ver2.icon.close-white {
  background-image: url('../images/ic_close_white.svg');
}
.button-ver2.icon.help {
  background-image: url('../images/ic_info.svg');
}
.button-ver2.icon.help-mini {
  width: 16px;
  height: 16px;
  background-image: url('../images/ic_info_small.svg');
}
.button-ver2.icon.clear {
  background-image: url('../images/ic_clear.svg');
}
.button-ver2.icon.lock {
  background-image: url('../images/ic_lock.svg');
}
.button-ver2.icon.search {
  background-image: url('../images/ic_search.svg');
}
.button-ver2.icon.prev {
  background-image: url('../images/ic_arrow_left.svg');
}
.button-ver2.icon.mes {
  background-image: url('../images/ic_mes.svg');
}
.button-ver2.icon.warring {
  background-image: url('../images/ic_warning.svg');
  opacity: 1 !important;
}
.button-ver2.icon.duplicate {
  background-image: url('../images/ic_duplicate_small.svg');
  width: 16px;
  height: 16px;
}
.button-ver2.icon.arrow-down {
  background-image: url('../images/selector.svg');
}
.button-ver2.icon.min-max.max {
  background-image: url('../images/ic_collapse_small.svg');
}
.button-ver2.icon.min-max.min {
  background-image: url('../images/ic_expand_small.svg');
}
.button-ver2.trash-popup {
  position: absolute;
  z-index: 10000;
  top: -6px;
  left: -6px;
  width: 28px;
  height: 28px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  transform: scale(0.3);
  opacity: 0;
  transition: transform cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms;
}
.button-ver2.trash-popup::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('../images/ic_trash_midle.svg') no-repeat center center;
  opacity: 0.3;
  background-size: 24px 24px;
}
.button-ver2.trash-popup:hover::before {
  opacity: 1;
}
.button-ver2.trash-popup.mini {
  width: 20px;
  height: 20px;
}
.button-ver2.trash-popup.mini::before {
  background: url('../images/ic_trash_mini.svg') no-repeat center center;
}
.button-ver2.trash-popup.mega {
  width: 32px;
  height: 32px;
}
.button-ver2.trash-popup.mega::before {
  background: url('../images/ic_trash.svg') no-repeat center center;
}
.button-ver2.white {
  display: inline-block;
  width: auto;
  padding: 0 24px;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  font-size: 14px;
  color: var(--black);
  text-transform: uppercase;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  transition: box-shadow 80ms ease 0s, border-top-color 80ms ease 0s, border-right-color 80ms ease 0s, border-bottom-color 80ms ease 0s, border-left-color 80ms ease 0s, background-color 80ms ease 0s;
}
.button-ver2.white:hover {
  border-color: rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
}
.button-ver2.white:active {
  background-color: #fafafa;
  border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.22);
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  transition: box-shadow 20ms ease 0s, border-color 20ms ease 0s;
}
.button-ver2.bc-temp-white {
  text-align: center;
  cursor: pointer;
  color: var(--black);
  background-color: var(--white);
  font-size: 14px;
  line-height: 34px;
  border-radius: 7px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
  transition: box-shadow 80ms ease, border-color 80ms ease, background-color 80ms ease;
  min-width: 100px;
}
.button-ver2.bc-temp-white.gray {
  background-color: var(--greyLight20);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom-color: rgba(0, 0, 0, 0.13);
}
.button-ver2.bc-temp-white:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.button-ver2.bc-temp-white:active {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.22);
  transition: box-shadow 20ms ease, border-color 20ms ease;
}
.button-ver2.bc-temp-white:active:not(.add) {
  background-color: #053748 !important;
  border-color: #053748 !important;
  color: var(--white);
}
.button-ver2.bc-temp-white.act {
  background-color: #053748 !important;
  border-color: #053748 !important;
  color: var(--white);
  cursor: default !important;
}
.button-ver2.white-rounded,
.button-ver2.orange-rounded,
.button-ver2.blue-rounded,
.button-ver2.light-blue-rounded {
  min-width: 100px;
  height: 34px;
  line-height: 32px;
  padding: 0 24px;
  text-align: center;
  color: var(--black);
  background-color: var(--white);
  font-size: 14px;
  border-radius: 7px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
  transition: box-shadow 80ms ease, border-color 80ms ease, background-color 80ms ease;
  text-transform: uppercase;
  text-decoration: none !important;
}
.button-ver2.white-rounded.gray,
.button-ver2.orange-rounded.gray,
.button-ver2.blue-rounded.gray,
.button-ver2.light-blue-rounded.gray {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.07);
  color: var(--grey) !important;
}
.button-ver2.white-rounded:hover,
.button-ver2.orange-rounded:hover,
.button-ver2.blue-rounded:hover,
.button-ver2.light-blue-rounded:hover,
.button-ver2.white-rounded:focus,
.button-ver2.orange-rounded:focus,
.button-ver2.blue-rounded:focus,
.button-ver2.light-blue-rounded:focus {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border-top-color: rgba(0, 0, 0, 0.17);
  border-left-color: rgba(0, 0, 0, 0.17);
  border-right-color: rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  outline: none;
}
.button-ver2.white-rounded:active,
.button-ver2.orange-rounded:active,
.button-ver2.blue-rounded:active,
.button-ver2.light-blue-rounded:active {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-left-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.22);
  transition: box-shadow 20ms ease, border-color 20ms ease;
}
.button-ver2.orange-rounded {
  background-color: #e9604a;
  border: none !important;
  color: var(--white);
  line-height: 34px;
}
.button-ver2.orange-rounded:active {
  background-color: #d9503a;
}
.button-ver2.orange-rounded.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.button-ver2.blue-rounded {
  background-color: #448af1;
  border: none !important;
  color: var(--white);
  line-height: 36px;
  height: 36px;
}
.button-ver2.blue-rounded:active {
  background-color: #347ae1;
}
.button-ver2.blue-rounded.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.button-ver2.light-blue-rounded {
  background-color: var(--blue);
  border: none !important;
  color: var(--white);
  line-height: 36px;
  height: 36px;
}
.button-ver2.light-blue-rounded:active {
  background-color: #347ae1;
}
.button-ver2.light-blue-rounded.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.button-ver2.green {
  display: inline-block;
  width: auto;
  height: 36px;
  line-height: 36px;
  border-radius: 7px;
  background-color: var(--green);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  font-size: 14px;
  color: var(--white);
  padding: 0 24px;
}
.button-ver2.green:hover {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
}
.button-ver2.border-icon {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(32, 32, 32, 0.08), 0 1px 0 0 rgba(32, 32, 32, 0.16);
  position: relative;
  overflow: hidden;
}
.button-ver2.border-icon::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 60ms;
}
.button-ver2.border-icon:hover::before {
  opacity: 0.3;
}
.button-ver2.border-icon:active {
  background-color: #fafafa;
}
.button-ver2.border-icon.add::before {
  background-image: url('../images/ic_add_small.svg');
}
.button-ver2.border-icon.dotted::before {
  background-image: url('../images/doted.svg');
}
.cf-input {
  border-radius: 7px !important;
  background-color: rgba(0, 0, 0, 0.01) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(0, 0, 0, 0.12) !important;
  outline: none !important;
  color: var(--black);
  transition: background-color 100ms, border 100ms, background-color 100ms;
}
.cf-input.cf-input_lg {
  height: 36px;
  line-height: 34px;
  width: 100%;
  padding: 0 10px;
}
.cf-input.cf-input_fsz {
  font-size: 14px;
}
.cf-input:hover {
  background-color: rgba(0, 0, 0, 0.01) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(0, 0, 0, 0.24) !important;
}
.cf-input:focus,
.cf-input:active,
.cf-input.focus {
  background-color: rgba(0, 0, 0, 0.01) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(0, 0, 0, 0.24) !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input),
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus),
.cf-input.error:not(:focus),
.cf-input.super-error,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input),
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus) {
  background-color: rgba(233, 96, 74, 0.02) !important;
  box-shadow: inset 0 1px 3px 0 rgba(81, 0, 0, 0.08) !important;
  border: solid 1px #e9604a !important;
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input)::-webkit-input-placeholder,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus)::-webkit-input-placeholder,
.cf-input.error:not(:focus)::-webkit-input-placeholder,
.cf-input.super-error::-webkit-input-placeholder,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input)::-webkit-input-placeholder,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus)::-webkit-input-placeholder {
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input)::-moz-placeholder,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus)::-moz-placeholder,
.cf-input.error:not(:focus)::-moz-placeholder,
.cf-input.super-error::-moz-placeholder,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input)::-moz-placeholder,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus)::-moz-placeholder {
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input):-moz-placeholder,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus):-moz-placeholder,
.cf-input.error:not(:focus):-moz-placeholder,
.cf-input.super-error:-moz-placeholder,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input):-moz-placeholder,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus):-moz-placeholder {
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input):-ms-input-placeholder,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus):-ms-input-placeholder,
.cf-input.error:not(:focus):-ms-input-placeholder,
.cf-input.super-error:-ms-input-placeholder,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input):-ms-input-placeholder,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus):-ms-input-placeholder {
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input):-o-input-placeholder,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus):-o-input-placeholder,
.cf-input.error:not(:focus):-o-input-placeholder,
.cf-input.super-error:-o-input-placeholder,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input):-o-input-placeholder,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus):-o-input-placeholder {
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input):input-placeholder,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus):input-placeholder,
.cf-input.error:not(:focus):input-placeholder,
.cf-input.super-error:input-placeholder,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input):input-placeholder,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus):input-placeholder {
  color: #e9604a !important;
}
.cf-input:empty:not(.auto-error-off):not(:focus):not(input)::before,
.cf-input.empty:not(.blocks-suggest):not(.auto-error-off):not(.focus)::before,
.cf-input.error:not(:focus)::before,
.cf-input.super-error::before,
.cf-input .error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input)::before,
.cf-input.ng-empty.ng-touched.cf-validate:not(:focus)::before {
  color: #e9604a !important;
}
.cf-input.error:not(:focus).attr-error.icon-right,
.cf-input.super-error.attr-error.icon-right {
  padding-right: 30px;
}
.cf-input.error:not(:focus).attr-error.icon-right [class^='icon-'],
.cf-input.super-error.attr-error.icon-right [class^='icon-'],
.cf-input.error:not(:focus).attr-error.icon-right [class*='icon-'],
.cf-input.super-error.attr-error.icon-right [class*='icon-'] {
  right: 4px;
}
.cf-input.error:not(:focus).attr-error.has-icon [class^='icon-'],
.cf-input.super-error.attr-error.has-icon [class^='icon-'],
.cf-input.error:not(:focus).attr-error.has-icon [class*='icon-'],
.cf-input.super-error.attr-error.has-icon [class*='icon-'] {
  display: block;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
.cf-input.cf-input_textarea-multiline {
  white-space: pre-wrap !important;
  padding: 4px 6px 0 !important;
}
.error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input) {
  background-color: rgba(233, 96, 74, 0.02) !important;
  box-shadow: inset 0 1px 3px 0 rgba(81, 0, 0, 0.08) !important;
  border: solid 1px #e9604a !important;
  color: #e9604a !important;
}
.error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input):input-placeholder {
  color: #e9604a !important;
}
.error-on-parent .cf-input:not(.auto-error-off):not(:focus):not(input)::before {
  color: #e9604a !important;
}
.dib {
  display: inline-block;
  width: 100%;
}
.let-sp-xs {
  letter-spacing: -0.1px;
}
[focus-mask] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.act [focus-mask],
.image-mini [focus-mask],
.gallery [focus-mask],
.found [focus-mask] {
  pointer-events: none !important;
  display: none !important;
  z-index: -100 !important;
}
.hidden {
  display: none !important;
}
[flex] {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}
[flex][direction='column'] {
  flex-direction: column;
}
[flex][align|='start'] {
  justify-content: flex-start;
}
[flex][align|='end'] {
  justify-content: flex-end;
}
[flex][align~='between'] {
  justify-content: space-between;
}
[flex][align~='center'] {
  align-items: center;
}
[flex='50'] {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
}
[flex='50'][direction='column'] {
  max-height: 50%;
}
[flex='50'][direction='row'] {
  max-width: 50%;
}
[contenteditable]:empty:before {
  content: attr(placeholder);
  display: block;
  color: var(--grey);
}
.error *[contenteditable]:empty:before {
  color: #e9604a;
}
.selector-ver2 {
  width: 240px;
  height: 36px;
  line-height: 34px;
  background-color: var(--white);
  font-size: 16px;
  color: var(--black);
  position: relative;
  margin: 0 0 24px;
  white-space: nowrap;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.24);
  -webkit-user-select: none;
  user-select: none;
}
.selector-ver2 .label {
  cursor: pointer;
  padding: 0 24px 0 12px;
}
.selector-ver2 .label::after {
  content: '';
  width: 24px;
  height: 24px;
  font-size: 18px;
  position: absolute;
  right: 6px;
  top: 5px;
  background: url('../images/ic-dropdown-white.svg');
}
.selector-ver2 div.disabled {
  color: #9b9b9b;
  pointer-events: none;
}
.selector-ver2 div.disabled:hover {
  background-color: var(--white);
}
.selector-ver2 .current {
  pointer-events: none;
  font-weight: bold;
}
.selector-ver2 > ul {
  display: none;
  position: absolute;
  top: -2px;
  left: -1px;
  width: 240px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  z-index: 10;
  max-height: 274px;
  overflow: auto;
}
.selector-ver2 > ul li {
  display: block;
  padding: 0 12px;
  margin: 0;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.selector-ver2 > ul li:last-child {
  border-radius: 0 0 3px 0;
}
.selector-ver2 > ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.selector-ver2 > ul li.disabled {
  color: #9b9b9b;
  pointer-events: none;
}
.selector-ver2 > ul li.disabled:hover {
  background-color: var(--white);
}
.selector-ver2 > ul::after {
  content: '';
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 0;
  line-height: 32px;
}
.selector-ver2 .popup {
  display: none;
  position: absolute;
  top: -2px;
  left: -1px;
  width: 240px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12), 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.selector-ver2 .popup > ul {
  padding: 0;
  margin: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-height: 274px;
}
.selector-ver2 .popup > ul li {
  display: block;
  padding: 0 12px;
  margin: 0;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.selector-ver2 .popup > ul li:last-child {
  border-radius: 0 0 3px 0;
}
.selector-ver2 .popup > ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.selector-ver2 .popup > ul li.disabled {
  color: #9b9b9b;
  pointer-events: none;
}
.selector-ver2 .popup > ul li.disabled:hover {
  background-color: var(--white);
}
.selector-ver2 .popup > ul::after {
  content: '';
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 0;
  line-height: 32px;
}
.selector-ver2.act > ul {
  z-index: 100;
  display: block;
}
.selector-ver2.act .popup {
  z-index: 100;
  display: block;
}
.textRed {
  color: #e9604a !important;
}
.tooltip_darkblue .tooltip-inner {
  background-color: #053748;
}
.tooltip_darkblue.left .tooltip-arrow {
  border-left-color: #053748;
}
.tooltip_darkblue.right .tooltip-arrow {
  border-right-color: #053748;
}
.tooltip_darkblue.top .tooltip-arrow {
  border-top-color: #053748;
}
.tooltip_darkblue.bottom .tooltip-arrow {
  border-bottom-color: #053748;
}
.message-us {
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 5px 10px 5px 0;
  margin: 0;
  white-space: nowrap;
  text-align: right;
  display: inline-block;
  position: relative;
  background: var(--white);
  color: #0084ff;
  width: 105px;
  max-width: 105px;
  min-width: 105px;
  cursor: pointer;
  text-decoration: none;
}
.message-us:hover {
  background: #0084ff;
  color: var(--white);
  border: 1px solid transparent;
}
.message-us:hover::before {
  background: transparent url('../images/message-us/message-us_active.png') no-repeat;
}
.message-us::after {
  content: 'Message Us';
}
.message-us::before {
  content: '';
  position: absolute;
  display: block;
  left: 5px;
  width: 16px;
  height: 16px;
  background: transparent url('../images/message-us/message-us.png') no-repeat;
}
.message-us_active {
  background: #0084ff;
  color: var(--white);
  border: 1px solid transparent;
}
.message-us_active::before {
  background: transparent url('../images/message-us/message-us_active.png') no-repeat;
}
div.uib-datepicker {
  padding: 0;
  margin: 0 0 24px;
}
div.uib-datepicker .uib-daypicker {
  margin: 0 auto;
  width: 100%;
  outline: none;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th {
  background-color: var(--greyLight20);
  height: 32px;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th:first-child {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th:last-child {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-title {
  text-transform: uppercase;
  font-size: 14px;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-left,
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-right {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.3;
  display: block;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-left:hover,
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-right:hover {
  opacity: 1;
}
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-left {
  background-image: url('../images/uib-datepicker/ic_arrow_left.svg');
}
div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-right {
  background-image: url('../images/uib-datepicker/ic_arrow_right.svg');
}
div.uib-datepicker .uib-daypicker thead tr:last-child th {
  padding: 12px 0;
}
div.uib-datepicker .uib-daypicker thead tr:last-child th small {
  font-size: 10px;
  color: #807e7e;
  text-transform: uppercase;
}
div.uib-datepicker .uib-daypicker thead tr:last-child th small.saturday,
div.uib-datepicker .uib-daypicker thead tr:last-child th small.sunday {
  color: #e9604a;
}
div.uib-datepicker .uib-daypicker tbody tr td {
  text-align: center;
  padding: 0 0 6px;
}
div.uib-datepicker .uib-daypicker tbody tr td button {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  min-width: initial;
}
div.uib-datepicker .uib-daypicker tbody tr td button span {
  display: inline-block;
  margin-bottom: 1px;
}
div.uib-datepicker .uib-daypicker tbody tr td button.text-muted {
  display: none;
}
div.uib-datepicker .uib-daypicker tbody tr td button.orange {
  background-color: #e9604a;
  color: var(--white);
}
div.uib-datepicker .uib-daypicker tbody tr td button.orange:hover {
  background-color: #eb7461;
  color: var(--white) !important;
}
div.uib-datepicker .uib-daypicker tbody tr td button.btn-info {
  background-color: #448af1 !important;
  color: var(--white) !important;
  border: none !important;
  cursor: auto;
}
div.uib-datepicker .uib-daypicker tbody tr td button.has-event {
  position: relative;
}
div.uib-datepicker .uib-daypicker tbody tr td button.has-event::after {
  content: ' ';
  top: 27px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: url('../images/uib-datepicker/ic_cal_event.svg') left center;
  height: 3px;
  width: 6px;
  margin-left: 1.5px;
}
div.uib-datepicker .uib-daypicker tbody tr td button.has-event.n1::after {
  width: 6px;
  margin-left: 1.5px;
}
div.uib-datepicker .uib-daypicker tbody tr td button.has-event.n2::after {
  width: 9px;
  margin-left: 0;
}
div.uib-datepicker .uib-daypicker tbody tr td button.has-event.n3::after {
  width: 15px;
  margin-left: 0;
}
div.uib-datepicker .uib-daypicker tbody tr td button:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
div.uib-datepicker .uib-daypicker tbody tr td.prev-day button {
  color: #807e7e;
  background-color: transparent;
}
div.uib-datepicker .uib-daypicker tbody tr td.prev-day button:hover {
  background-color: var(--white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  color: #807e7e;
}
div.uib-datepicker .uib-daypicker tbody tr td.prev-day button.has-event {
  background-color: #d8d8d8;
  color: var(--white);
}
div.uib-datepicker .uib-daypicker tbody tr td.prev-day button.btn-info {
  background-color: #448af1;
  color: var(--white);
}
div.uib-datepicker button {
  border: none;
  background: none transparent;
  font-family: var(--font);
  font-weight: 400;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
div.uib-datepicker button strong {
  font-weight: 400;
}
.schedule-datepicker input.daypicker-wrapper {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  border: none;
}
.schedule-datepicker ul {
  position: absolute;
  top: -99999px;
  opacity: 0;
  min-width: 112px;
  min-height: 32px;
  padding: 0;
  margin: 0;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.schedule-datepicker ul li {
  display: block;
  padding: 0;
  margin: 0;
}
.schedule-datepicker ul li a {
  display: block;
  font-size: 16px;
  line-height: 32px;
  color: var(--black);
  padding: 0 0 0 16px;
  margin: 0;
  text-decoration: none;
  text-align: left;
  font-weight: 400;
}
.schedule-datepicker ul li a:hover {
  text-decoration: underline;
}
.schedule-datepicker ul.uib-datepicker-popup.dropdown-menu {
  opacity: 1 !important;
  z-index: var(--zindex-dropdown);
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
}
.schedule-datepicker div.uib-datepicker {
  padding: 10px 10px 0 10px;
  margin: 0;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker {
  margin: 0 auto;
  width: 100%;
  outline: none;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th {
  background-color: var(--white);
  height: 32px;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th:first-child {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th:last-child {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-title {
  text-transform: capitalize;
  font-size: 14px;
  color: #9b9b9b;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-left,
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-right {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.3;
  display: block;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-left:hover,
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-right:hover {
  opacity: 1;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-left {
  background-image: url('../images/uib-daypicker/ic_arrow_left.svg');
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:first-child th .uib-right {
  background-image: url('../images/uib-daypicker/ic_arrow_right.svg');
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:last-child th {
  padding: 6px 0 12px 0;
  text-align: center;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:last-child th small {
  font-size: 10px;
  color: #807e7e;
  text-transform: uppercase;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:last-child th small.saturday,
.schedule-datepicker div.uib-datepicker .uib-daypicker thead tr:last-child th small.sunday {
  color: #e9604a;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td {
  text-align: center;
  padding: 0 0 6px;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button {
  width: 50px;
  height: 36px;
  border-radius: 7px;
  min-width: initial;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button span {
  display: inline-block;
  margin-bottom: 1px;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.text-muted {
  display: none;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.orange {
  background-color: #e9604a;
  color: var(--white);
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.orange:hover {
  background-color: #eb7461;
  color: var(--white) !important;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.btn-info {
  background-color: #053748 !important;
  color: var(--white) !important;
  border: none !important;
  cursor: auto;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.has-event {
  position: relative;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.has-event::after {
  content: ' ';
  top: 27px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: url('../images/uib-daypicker/ic_cal_event.svg') left center;
  height: 3px;
  width: 6px;
  margin-left: 1.5px;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.has-event.n1::after {
  width: 6px;
  margin-left: 1.5px;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.has-event.n2::after {
  width: 9px;
  margin-left: 0;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button.has-event.n3::after {
  width: 15px;
  margin-left: 0;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td button:hover {
  background-color: var(--white);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.16);
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td.prev-day button {
  color: #e0e0e0 !important;
  background-color: transparent !important;
  pointer-events: none;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td.prev-day button span {
  color: #e0e0e0 !important;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td.prev-day button:hover {
  background-color: var(--white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  color: #e0e0e0;
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td.prev-day button.has-event {
  background-color: #d8d8d8;
  color: var(--white);
}
.schedule-datepicker div.uib-datepicker .uib-daypicker tbody tr td.prev-day button.btn-info {
  background-color: #053748;
  color: var(--white);
}
.schedule-datepicker div.uib-datepicker button {
  border: none;
  background: none transparent;
  font-family: var(--font);
  font-weight: 400;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}
.schedule-datepicker div.uib-datepicker button strong {
  font-weight: 400;
}
.selector:not(.selector-bot) {
  width: 240px;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  font-size: 16px;
  color: var(--black);
  position: relative;
  margin: 0 0 24px;
  white-space: nowrap;
}
.selector:not(.selector-bot) .label {
  cursor: pointer;
  padding: 0 24px 0 12px;
}
.selector:not(.selector-bot) .label::after {
  content: '▾';
  font-size: 18px;
  position: absolute;
  right: 5px;
  top: 0;
}
.selector:not(.selector-bot) div.disabled {
  color: #9b9b9b;
}
.selector:not(.selector-bot) div.disabled:hover {
  background-color: var(--white);
}
.selector:not(.selector-bot) ul {
  display: none;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 240px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  z-index: 10;
  max-height: 190px;
  overflow: auto;
}
.selector:not(.selector-bot) ul li {
  display: block;
  padding: 0 12px;
  margin: 0;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.selector:not(.selector-bot) ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.selector:not(.selector-bot) ul li.disabled {
  color: #9b9b9b;
}
.selector:not(.selector-bot) ul li.disabled:hover {
  background-color: var(--white);
}
.selector:not(.selector-bot) ul::after {
  content: '▾';
  font-size: 18px;
  position: absolute;
  right: 5px;
  top: 0;
}
.selector:not(.selector-bot).act ul {
  z-index: 100;
  display: block;
}
label.checkbox-style-label {
  position: relative;
  padding-left: 36px;
  -webkit-user-select: none;
  user-select: none;
}
label.checkbox-style-label input {
  position: absolute;
  z-index: 10;
  opacity: 0;
  width: 24px;
  height: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
label.checkbox-style-label span {
  position: absolute;
  z-index: 10;
  width: 24px;
  height: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  border-radius: 3px;
  background-color: var(--white);
  border: solid 2px #b2b2b2;
  cursor: pointer;
}
label.checkbox-style-label input:checked + span {
  background: #448af1 url('../images/checkbox/ic_done_white.svg') no-repeat center center;
  border: none;
}
label.checkbox-style-label.mini {
  padding-left: 22px;
}
label.checkbox-style-label.mini input,
label.checkbox-style-label.mini span {
  width: 16px;
  height: 16px;
  background-size: cover !important;
}
.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  height: 36px;
  width: 36px;
  background: transparent url('../images/preloader/spinner_b.svg') no-repeat 50% 50%;
  background-size: 36px;
  z-index: 1000;
}
.botCloning {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 14px;
  margin-left: -32px;
  height: 36px;
  width: 80px;
  color: var(--black);
  font-size: 12px;
}
html {
  height: 100%;
  background-color: var(--greyLight20);
}
html body {
  min-height: 100%;
  height: 100%;
  font-family: var(--font);
  width: 100%;
  position: fixed;
  padding-top: 64px;
  -webkit-font-smoothing: antialiased;
}
html body section h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 56px;
  color: var(--black);
  margin: 0;
  padding-top: 96px;
  text-align: center;
}
html body span.red {
  color: #e9604a;
}
html body span.blue {
  color: #4db6d6;
}
html body #react-root {
  height: 100%;
}
html #intercom-container style {
  display: none;
}
html .fb_reset > div {
  min-width: unset !important;
  max-width: unset !important;
  padding: unset !important;
  margin: unset !important;
}
html appcues-container {
  min-width: unset !important;
  max-width: unset !important;
  padding: unset !important;
  margin: unset !important;
}
body header .logo-mini {
  display: none;
}
body header .logo {
  pointer-events: none;
  top: 210px;
  left: 16px;
  transition: opacity 200ms 0ms linear;
  -webkit-transition: opacity 200ms 0ms linear;
}
body header .logo img {
  width: 290px;
}
body header nav {
  right: 81px;
  top: 24px;
  text-align: right;
  white-space: nowrap;
}
body header nav a {
  display: inline-block;
  vertical-align: top;
  margin-left: 36px;
  color: var(--white);
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: border-bottom-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: border-bottom-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
body header nav a:hover {
  border-bottom-color: #ffffff;
}
body header .language-selector {
  cursor: pointer;
  top: 22px;
  right: 16px;
  width: 25px;
  height: 20px;
  line-height: 18px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  color: var(--white);
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  transition: border-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: border-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
body header .language-selector .popup {
  position: absolute;
  top: -1000px;
  right: 0;
  width: 120px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  padding: 6px 0;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 600ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 60ms;
  -webkit-transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 600ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 60ms;
}
body header .language-selector .popup a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  line-height: 40px;
  color: var(--black);
  text-transform: none;
  text-align: left;
  padding-left: 24px;
}
body header .language-selector .popup a:hover {
  color: #e9604a;
}
body header .language-selector .popup::before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 40px;
  top: -40px;
  right: 0;
}
body header .language-selector:hover {
  border-color: #ffffff;
}
body header .language-selector:hover .popup {
  transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 0ms;
  -webkit-transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 0ms;
  top: 23px;
  opacity: 1;
  max-height: 300px;
  overflow: visible;
}
body header .language-selector::after {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  right: 0;
}
body header {
  max-width: inherit;
}
body header > div .logo-mini {
  display: block;
  top: 22px;
  left: 32px;
  width: 103px;
}
body header > div .logo-mini img {
  width: 100%;
}
body header > div .button {
  top: 18px;
  right: 360px;
}
body header > div nav {
  right: 22px;
  top: 0px;
  height: 64px;
  text-align: right;
  white-space: nowrap;
}
body header > div nav > a {
  display: inline-block;
  vertical-align: top;
  height: 64px;
  line-height: 64px;
  margin-left: 10px;
  padding: 0 8px;
  color: var(--black);
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(233, 96, 74, 0);
  transition: all 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: all 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-user-select: none;
  user-select: none;
}
body header > div nav > a:hover {
  color: #e9604a;
}
body header > div nav > a.act {
  border-bottom-color: #e9604a;
}
body header > div nav > a.button.big-orange {
  background-color: #e9604a;
  border: 1px solid #e9604a;
  border-radius: 3px;
  color: var(--white);
  display: inline-block;
  font-size: 12px;
  height: 28px;
  line-height: 26px;
  margin-left: 20px;
  margin-top: 19px;
  width: 97px;
}
body header > div .language-selector {
  cursor: pointer;
  top: 22px;
  right: 16px;
  width: 25px;
  height: 20px;
  line-height: 18px;
  border: 1px solid #000000;
  border-radius: 3px;
  color: var(--black);
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  transition: all 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: all 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
body header > div .language-selector .popup {
  position: absolute;
  top: -1000px;
  right: 0;
  width: 120px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  padding: 6px 0;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 600ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 60ms;
  -webkit-transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 600ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 60ms;
}
body header > div .language-selector .popup a {
  position: static;
  display: block;
  text-decoration: none;
  font-size: 16px;
  line-height: 40px;
  color: var(--black);
  text-transform: none;
  text-align: left;
  padding-left: 24px;
}
body header > div .language-selector .popup a:hover {
  color: #e9604a;
}
body header > div .language-selector .popup::before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 40px;
  top: -40px;
  right: 0;
}
body header > div .language-selector:hover {
  color: #e9604a;
  border-color: #e9604a;
}
body header > div .language-selector:hover .popup {
  transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 0ms;
  -webkit-transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 0ms;
  top: 23px;
  opacity: 1;
  max-height: 300px;
  overflow: visible;
}
body header > div .language-selector::after {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  right: 0;
}
body header > div .user {
  cursor: pointer;
  display: inline-block;
  position: relative;
  line-height: 64px;
}
body header > div .user > a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  margin-left: 16px;
  border-bottom-color: transparent;
}
body header > div .user > a .ava {
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 28px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 8px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
body header > div .user > a .name {
  font-size: 12px;
  text-transform: uppercase;
}
body header > div .user .popup {
  top: 52px;
  right: 0;
  position: absolute;
  border-radius: 4px;
  background-color: var(--white);
  padding: 4px 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 16px 40px 0 rgba(0, 0, 0, 0.08);
  z-index: 10;
}
body header > div .user .popup > a {
  display: block;
  height: 28px;
  font-size: 15px;
  line-height: 28px;
  padding: 0 12px 0 8px;
  color: var(--black);
  text-transform: none;
  margin: 0;
  text-align: left;
}
body header > div .user .popup > a:hover {
  background-color: var(--blue);
  color: var(--white);
}
body.is-404 header .logo {
  display: block;
  top: 22px;
  left: 16px;
  width: 103px;
  pointer-events: auto;
}
body.is-404 header .logo img {
  width: 100%;
}
.whiteLabelLogo {
  position: absolute;
  top: 12px;
  left: 42px;
}
body footer {
  display: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 160px;
  background-color: var(--black);
  transition: height 200ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: height 200ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  overflow: hidden;
}
body footer > div {
  padding: 0 16px;
}
body footer > div .copy {
  text-align: left;
  line-height: 160px;
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--white);
}
body footer > div .links {
  position: absolute;
  right: 16px;
  line-height: 160px;
  top: 0;
  margin: 0;
}
body footer > div .links a {
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transition: border-bottom-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
  -webkit-transition: border-bottom-color 60ms 0ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
body footer > div .links a:hover {
  border-bottom-color: #ffffff;
}
@media (max-width: 720px) {
  body footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    line-height: 16px;
  }
  body footer > div .copy {
    line-height: 16px;
    text-align: center;
    padding-top: 24px;
  }
  body footer > div .links {
    line-height: 16px;
    top: 60px;
    right: 50%;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    white-space: nowrap;
  }
  body footer > div .links > a {
    margin-left: 20px;
  }
  body footer > div .links > a:first-child {
    margin: 0;
  }
}
@media (max-width: 790px) {
  body {
    padding-top: 58px !important;
  }
  body.mobile-warring-show {
    padding-top: 116px !important;
  }
  body.mobile-warring-show header {
    top: 60px;
  }
  body.mobile-warring-show section.bot-selector {
    top: 60px;
  }
  body.mobile-warring-show section.bot-selector > div nav {
    top: 124px;
  }
  body.mobile-warring-show section.bot-selector > div nav ul {
    min-height: 610px;
  }
  body.mobile-warring-show section.bot-selector > div nav ul .bottom-list {
    bottom: 124px;
  }
  body.mobile-warring-show section.editor {
    margin-top: 8px;
  }
  body.mobile-warring-show.header-fuckups {
    padding-top: 152px !important;
  }
  body.mobile-warring-show.header-fuckups div.alert.fuckups {
    min-width: 300px;
  }
  body.mobile-warring-show.header-fuckups div.alert.fuckups.act {
    top: 60px;
  }
  body.mobile-warring-show.header-fuckups section.bot-selector {
    top: 96px !important;
  }
  body.mobile-warring-show.header-fuckups section.bot-selector > div nav {
    top: 160px !important;
  }
  body.mobile-warring-show.header-fuckups section.bot-selector > div nav ul {
    min-height: 646px !important;
  }
  body.mobile-warring-show.header-fuckups section.bot-selector > div nav ul .bottom-list {
    bottom: 160px !important;
  }
  body section.promotion .border-box.generator li label {
    display: block;
  }
  body section.settings {
    min-width: 1076px;
  }
  body section.dashboard-v2 {
    overflow: auto;
    margin-top: 0;
    padding-bottom: 0;
  }
  body section.dashboard-v2 > div {
    padding: 32px 16px 10px;
  }
  body section.bot-selector > div .button-box {
    height: 64px;
    overflow: hidden;
  }
  body section.bot-selector > div nav {
    max-width: 90%;
    overflow: hidden;
  }
  section.bot-create > div {
    max-width: 100%;
    min-width: 100%;
  }
  section.bot-create > div .bots-list {
    padding-bottom: 40px;
    overflow: hidden;
  }
  section.bot-create > div .bot-panel {
    padding: 32px 50px;
  }
  section.bot-create > div .bot-panel .bot-panel-container {
    width: auto;
    text-align: center;
  }
  section.bot-create > div .bot-panel .bot-panel-container .bot-name {
    bottom: 23px;
  }
  section.bot-create > div .bot-panel .bot-panel-container .bot-name input {
    width: 100%;
    min-width: 256px;
    margin: 0;
  }
  section.bot-create > div .bot-panel .bot-panel-container .bot-name div {
    display: none;
  }
  section.bot-create > div .bot-panel .button {
    bottom: 10px;
  }
  .card-item.add::after {
    display: none !important;
  }
  .card-item.add ul {
    opacity: 1 !important;
  }
  .card-item .r-buttons,
  .card-item.gallery .card > .image .img,
  .button-image,
  .card-item .card .top-buttons {
    opacity: 1 !important;
  }
  .button.move,
  .button.drag {
    visibility: hidden;
  }
  div.button-picture-wrapper {
    display: block !important;
    opacity: 0.3 !important;
  }
  .card-item.gallery .card > .image div.img {
    opacity: 0.7 !important;
  }
}
@media (max-width: 1024px) {
  section.editor {
    padding-left: 0;
  }
}
section.editor {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 0;
  overflow-x: auto;
  background-color: #f3f2f2;
}
section.editor > div {
  max-width: inherit;
  height: 100%;
  margin: 0;
  position: relative;
}
section.editor > div .lcol {
  position: absolute;
  top: 0;
  left: 0;
  width: 314px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--white);
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.03), 1px 0 3px 0 rgba(0, 0, 0, 0.07);
}
section.editor > div .lcol .block-list {
  overflow-x: hidden;
}
section.editor > div .lcol .block-list .block-item {
  margin: 0 0 32px;
}
section.editor > div .lcol .block-list .block-item .title {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 0 0 14px;
  cursor: pointer;
}
section.editor > div .lcol .block-list .block-item .title span {
  padding-left: 3px;
  line-height: 30px;
}
section.editor > div .lcol .block-list .block-item .title input[type='text'] {
  line-height: 24px;
}
section.editor > div .lcol .block-list .block-item .title .button.edit {
  position: absolute;
  right: 0;
  top: 3px;
  width: 24px;
  height: 24px;
  background: url('../images/editor/ic_trash.svg') center center no-repeat;
  opacity: 0;
}
section.editor > div .lcol .block-list .block-item .title .button.locked {
  position: absolute;
  right: 0;
  top: 3px;
  width: 24px;
  height: 24px;
  background: url('../images/editor/ic_lock.svg') center center no-repeat;
  opacity: 0;
}
section.editor > div .lcol .block-list .block-item .title .button.move {
  position: absolute;
  left: 0;
  top: 3px;
  width: 12px;
  height: 24px;
  background: url('../images/editor/ic_drag.svg') center center no-repeat;
  opacity: 0;
}
section.editor > div .lcol .block-list .block-item .title ul {
  position: absolute;
  top: -99999px;
  opacity: 0;
  min-width: 112px;
  min-height: 32px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.02);
  padding: 0;
  margin: 0;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
}
section.editor > div .lcol .block-list .block-item .title ul li {
  display: block;
  padding: 0;
  margin: 0;
}
section.editor > div .lcol .block-list .block-item .title ul li a {
  display: block;
  font-size: 16px;
  line-height: 32px;
  color: var(--black);
  padding: 0 0 0 16px;
  margin: 0;
  text-decoration: none;
  text-align: left;
  font-weight: 400;
}
section.editor > div .lcol .block-list .block-item .title ul li a:hover {
  text-decoration: underline;
}
section.editor > div .lcol .block-list .block-item .title ul.act {
  top: 28px;
  right: 0;
  left: auto;
  opacity: 1;
  z-index: 1;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
}
section.editor > div .lcol .block-list .block-item .title:hover .button.edit {
  opacity: 0.3;
}
section.editor > div .lcol .block-list .block-item .title:hover .button.move {
  opacity: 0.3;
}
section.editor > div .lcol .block-list .block-item .title:hover .button.locked {
  opacity: 0.3;
}
section.editor > div .lcol .block-list .block-item .tags {
  margin: 4px 0;
  padding: 8px 0 4px 8px;
  background-color: var(--white);
  text-align: justify;
  border-radius: 3px;
}
section.editor > div .lcol .block-list .block-item .tags > span {
  display: inline-block;
  vertical-align: top;
  width: 31.3%;
  height: 32px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.02);
  font-size: 12px;
  line-height: 32px;
  color: var(--black);
  text-align: center;
  margin: 0 4px 4px 0;
  cursor: pointer;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
section.editor > div .lcol .block-list .block-item .tags > span.small {
  font-size: 11px;
}
section.editor > div .lcol .block-list .block-item .tags > span.act {
  background-color: #448af1;
  color: var(--white);
}
section.editor > div .lcol .block-list .block-item .tags > span.add {
  color: rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 60ms linear 0ms;
}
section.editor > div .lcol .block-list .block-item .tags > span.add:hover {
  color: #000000;
}
section.editor > div .lcol .block-list .block-item .tags:hover > span.add {
  opacity: 1;
}
section.editor > div .lcol .block-list .block-item.add {
  height: 40px;
  background-color: var(--white);
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  color: var(--black);
  border-radius: 3px;
}
section.editor > div .lcol .block-list .block-item.add::before {
  content: '+ ';
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-weight: bold;
}
section.editor > div .lcol .post-sender-scheduler {
  background-color: var(--white);
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.03), 1px 0 3px 0 rgba(0, 0, 0, 0.07);
  min-height: 100%;
  padding: 32px 0px;
}
section.editor > div .lcol .post-sender-scheduler > * {
  margin: 0 0 32px;
  padding: 0 24px;
}
section.editor > div .lcol .post-sender-scheduler > * .title {
  font-size: 13px;
  font-weight: 600;
  line-height: 26px;
  color: #767676;
  margin: 0 0px 6px;
  text-transform: uppercase;
  cursor: default;
}
section.editor > div .lcol .post-sender-scheduler > * .title span {
  font-size: 14px;
  float: right;
  font-weight: 400;
  text-align: right;
  width: 27%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 26px;
}
@media (min-width: 1300px) {
  section.editor > div .lcol .post-sender-scheduler > * .title span {
    width: 35%;
  }
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add {
  position: relative;
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add > span.popup {
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  overflow: hidden;
  padding: 10px 10px;
  position: absolute;
  left: 0;
  top: -1000px;
  transition: opacity 60ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, max-height 600ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms linear 60ms;
  z-index: 10;
  font-weight: 400;
  min-width: 120px;
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add > span.popup > * {
  display: block;
  font-size: 15px;
  width: 100%;
  white-space: nowrap;
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add > span.popup > input {
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  font-size: 16px;
  color: var(--black);
  padding: 0 5px;
  -webkit-appearance: none;
  outline: none;
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add > span.popup > a {
  color: var(--black);
  text-decoration: none;
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add > span.popup > a:hover {
  color: var(--black);
  text-decoration: underline;
}
section.editor > div .lcol .post-sender-scheduler .tags > span.add > span.popup.act {
  top: 32px;
  opacity: 1;
}
section.editor > div .lcol .post-sender-scheduler .send-now {
  margin: 0 0 32px;
  padding: 0 24px;
}
section.editor > div .lcol .post-sender-scheduler .send-now .button-ver2 {
  height: 36px;
  position: relative;
  width: 100%;
  margin: 0;
}
section.editor > div .lcol .post-sender-scheduler .send-now .button-ver2.add::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/editor/ic_add_small_new.svg) no-repeat center center;
  opacity: 0.3;
}
section.editor > div .lcol .post-sender-scheduler .send-now .button-ver2.add:hover::before {
  opacity: 1;
}
section.editor > div .lcol .post-sender-scheduler .send-now .button-ver2.add.act::before {
  background: url(../images/editor/ic_add_white_small.svg) no-repeat center center;
  opacity: 1;
}
section.editor > div .lcol .post-sender-scheduler .send-on-update .tags {
  text-align: justify;
}
section.editor > div .lcol .post-sender-scheduler .send-on-update .tags > span {
  display: inline-block;
  vertical-align: top;
  width: 32%;
  height: 32px;
  border-radius: 3px;
  background-color: var(--white);
  font-size: 12px;
  line-height: 32px;
  color: var(--black);
  text-align: center;
  margin: 0 0 4px;
  cursor: pointer;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}
section.editor > div .lcol .post-sender-scheduler .send-on-update .tags > span.small {
  font-size: 11px;
}
section.editor > div .lcol .post-sender-scheduler .send-on-update .tags > span.add {
  background-color: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-weight: bold;
}
section.editor > div .lcol .post-sender-scheduler .send-on-update .tags > span.act {
  pointer-events: none;
  color: var(--white);
  background-color: #448af1;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags {
  margin: -5px;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .tags-cell,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .tags-cell,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .tags-cell,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .tags-cell {
  width: 100%;
  padding: 5px;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags span,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags span,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags span,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags span {
  display: block;
  vertical-align: top;
  width: 100%;
  height: 36px;
  line-height: 34px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  margin: 0;
  padding: 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags span.act,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags span.act,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags span.act,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags span.act {
  background-color: #448af1;
  color: var(--white);
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .sequence-flex,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .sequence-flex,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .sequence-flex,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .sequence-flex {
  display: flex;
  justify-content: center;
  line-height: 16px;
  white-space: normal;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .sequence-flex .sequence-flex-item,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .sequence-flex .sequence-flex-item,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .sequence-flex .sequence-flex-item,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .sequence-flex .sequence-flex-item {
  align-self: center;
  -webkit-align-self: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .add,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .add,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .add,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .add {
  position: relative;
  margin-bottom: 0;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .add::before,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .add::before,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .add::before,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .add::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/editor/ic_add_small_new.svg') no-repeat center center;
  opacity: 0.3;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .add:hover::before,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .add:hover::before,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .add:hover::before,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .add:hover::before {
  opacity: 1;
}
section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags:after,
section.editor > div .lcol .post-sender-scheduler .autopost-group .tags:after,
section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags:after,
section.editor > div .lcol .post-sender-scheduler .sequence-events .tags:after {
  content: '';
  display: table;
  clear: both;
}
section.editor > div .lcol .block-error {
  position: relative;
}
section.editor > div .lcol .block-error::after {
  content: ' ';
  position: absolute;
  z-index: 5;
  right: 3px;
  top: 3px;
  width: 10px;
  height: 10px;
  border: 2px solid var(--white);
  background-color: #e9604a;
  border-radius: 50%;
}
section.editor > div .lcol .block-error.block-disabled::after {
  background-color: #cecece;
}
section.editor .rcol {
  padding: 32px 33px 0 351px;
  overflow-x: auto;
  position: relative;
  height: 100%;
}
section.editor .rcol > div {
  display: inline-block;
  min-width: 100%;
}
section.editor .rcol .disabled {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  opacity: 0.8;
}
section.editor .rcol .disabled h1 .button.close {
  pointer-events: auto;
}
section.editor .rcol .disabled h1 .button.copy {
  pointer-events: auto;
  opacity: 0.8 !important;
}
section.editor .rcol .disabled h1 .button.copy:hover {
  opacity: 1 !important;
}
section.editor .rcol .broadcast h1 {
  display: flex;
  margin: -4px 0 32px;
  cursor: default;
  align-items: baseline;
}
section.editor .rcol .broadcast h1 .send-now {
  font-weight: 600;
  text-transform: uppercase;
  padding-right: 31px;
  margin: 0 24px 0 0;
  height: 36px;
  line-height: 36px;
}
section.editor .rcol .broadcast h1 .text {
  padding: 0;
  margin: 0 8px 0 0;
  font-size: 24px;
  line-height: 1.5;
  align-self: center;
}
section.editor .rcol .broadcast h1 .h1-but {
  flex: 1;
  position: relative;
  text-align: right;
  height: auto;
}
section.editor .rcol .broadcast h1 .h1-but .button {
  vertical-align: -3px;
}
section.editor .rcol .broadcast h1 .h1-but .button.copy {
  position: absolute;
  left: 0;
  top: 7px;
}
section.editor .rcol .broadcast h1 .tooltip-copy {
  display: block !important;
  width: auto !important;
  height: auto !important;
}
section.editor .rcol .broadcast h1 .tooltip-copy .tooltip-inner {
  background-color: #053748;
}
section.editor .rcol .broadcast h1 .tooltip-copy .tooltip-arrow {
  border-top-color: #053748 !important;
}
section.editor .rcol .broadcast h1 .tooltip-switcher {
  display: block !important;
  width: auto;
  height: auto !important;
  margin-left: 6px;
  z-index: 19;
}
section.editor .rcol .broadcast h1 .tooltip-switcher .tooltip-inner {
  background-color: #053748;
  height: auto;
  text-align: center;
  white-space: nowrap;
}
section.editor .rcol .broadcast h1 .tooltip-switcher .tooltip-arrow {
  border-bottom-color: #053748 !important;
}
section.editor .rcol .broadcast h1 .test-bc-button {
  align-self: flex-end;
  margin: 0 24px 0 0;
}
section.editor .rcol .broadcast h1 .spacing {
  margin: 0 12px 0 0;
}
section.editor .rcol .broadcast .broadcasting-tags {
  padding-bottom: 48px;
  padding-top: 36px;
}
section.editor .rcol .broadcast .additional {
  display: block;
  margin: 0 0 32px;
  white-space: nowrap;
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
  padding: 24px 32px 24px 36px;
  margin: -32px -33px 32px -36px;
}
section.editor .rcol .broadcast .additional .info-autoposting {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
  white-space: normal;
  margin: 0;
}
section.editor .rcol .broadcast .additional .schedule-datepicker {
  display: inline-block;
  position: relative;
  margin-right: 16px;
}
section.editor .rcol .broadcast .additional .schedule-datepicker > div {
  display: inline-block;
}
section.editor .rcol .broadcast .additional .schedule-datepicker .datepicker-btn {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  color: #000000;
  height: 36px;
  position: relative;
  padding: 0 36px 0 16px;
  text-transform: capitalize;
  margin: 0;
  min-width: 120px;
}
section.editor .rcol .broadcast .additional .schedule-datepicker .datepicker-btn:after {
  content: '';
  background: transparent url('../images/editor/ic_dropdown.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 5px;
  right: 6px;
}
section.editor .rcol .broadcast .additional select {
  display: inline-block;
  height: 36px;
  line-height: 2;
  border-radius: 7px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
  text-align-last: center;
  margin: 0 16px 0 0px;
  outline: none;
  min-width: 120px;
  vertical-align: bottom;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 36px 0 16px;
  background: #ffffff url('../images/editor/ic_dropdown.svg') no-repeat 87px center;
  background-size: 24px;
  cursor: pointer;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-bottom-color: rgba(0, 0, 0, 0.17);
}
section.editor .rcol .broadcast .additional select option {
  font-weight: 300;
  font-size: 16px !important;
  line-height: normal;
  text-align: center;
  text-align-last: center;
  -webkit-appearance: none;
  appearance: none;
}
section.editor .rcol .broadcast .additional select:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border-top-color: rgba(0, 0, 0, 0.17);
  border-left-color: rgba(0, 0, 0, 0.17);
  border-right-color: rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  outline: none;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is) {
  min-width: 159px;
  vertical-align: bottom;
  margin-right: 16px;
  width: auto;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is) .selected {
  font-size: 14px;
  width: auto;
  position: relative;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is) ul {
  z-index: 100;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is) ul li {
  font-size: 14px;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is) ul li:first-child:after {
  position: absolute;
  content: '';
  top: 8px;
  right: 5px;
  z-index: 10;
  width: 24px;
  height: 24px;
  background: url('../images/editor/ic_dropdown.svg') no-repeat center center;
  pointer-events: none;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is):after {
  z-index: 2;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is).timezone-dropdown ul {
  display: flex;
  flex-direction: column;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is).timezone-dropdown ul li {
  order: 99;
}
section.editor .rcol .broadcast .additional .art-big-dropdown:not(.is).timezone-dropdown ul li.act {
  order: 0;
}
section.editor .rcol .broadcast .additional .timepicker-wrapper {
  display: inline-block;
  vertical-align: bottom;
}
section.editor .rcol .broadcast .additional .timepicker-wrapper input {
  text-align: center;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
  transition: box-shadow 80ms ease, border-color 80ms ease, background-color 80ms ease;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  color: #000000;
  height: 36px;
  position: relative;
  padding: 0;
  width: 88px;
  margin: 0 16px 0 0;
}
section.editor .rcol .broadcast .additional .timepicker {
  font-size: 14px;
  display: inline-block;
  margin-right: 16px;
  vertical-align: bottom;
}
section.editor .rcol .broadcast .additional .timepicker .uib-time input {
  width: 44px;
  height: 36px;
  text-align: center;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.12);
  outline: none;
  transition: background-color 100ms, border 100ms, background-color 100ms;
}
section.editor .rcol .broadcast .additional .timepicker .uib-time input:focus,
section.editor .rcol .broadcast .additional .timepicker .uib-time input:active,
section.editor .rcol .broadcast .additional .timepicker .uib-time input:hover {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.24);
}
section.editor .rcol .broadcast .additional .timepicker .uib-separator {
  opacity: 0.3;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  width: 12px;
  text-align: center;
}
section.editor .rcol .broadcast .additional .days-selector {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  width: 284px;
  height: 36px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.15);
  margin: 16px 0 0 0;
  padding: 0;
  overflow: hidden;
  font-size: 0;
  -webkit-user-select: none;
          user-select: none;
}
section.editor .rcol .broadcast .additional .days-selector > li {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 0;
  width: 14.29%;
  height: 34px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  background-color: #ffffff;
}
section.editor .rcol .broadcast .additional .days-selector > li.act {
  background-color: #448af1;
  color: #ffffff;
}
section.editor .rcol .broadcast .additional .schedule-recurrent,
section.editor .rcol .broadcast .additional .row {
  font-size: 0;
}
section.editor .rcol .broadcast .additional .sequence-content .row.row1 {
  margin-bottom: 16px;
}
section.editor .rcol .broadcast .additional .sequence-content .row .text {
  display: inline-block;
  width: 143px;
  padding-right: 16px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  vertical-align: middle;
}
section.editor .rcol .broadcast .additional .sequence-content .row .text .right {
  float: right;
}
section.editor .rcol .broadcast .additional .sequence-content .row .art-big-dropdown {
  vertical-align: middle;
}
section.editor .rcol .broadcast .additional .sequence-content .row .art-big-dropdown.send-in-type {
  min-width: 101px;
}
section.editor .rcol .broadcast .additional .sequence-content .row .last-inter-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.71;
  color: #767676;
}
section.editor .rcol .broadcast .additional .sequence-content .row .user-filter {
  margin: 16px 0 0 143px;
  font-size: 16px;
}
section.editor .rcol .broadcast .additional .sequence-content .row .send_in_num {
  height: 36px;
  width: 54px;
  vertical-align: middle;
  padding: 5px;
  margin-right: 16px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background: #ffffff !important;
}
section.editor .rcol .broadcast .additional .sequence-content .row .at {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  font-size: 16px;
}
section.editor .rcol .broadcast .additional .sequence-content .row .last-inter {
  display: inline-block;
  vertical-align: middle;
}
section.editor .rcol h1 {
  position: relative;
  line-height: 36px;
  font-size: 28px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #000000;
  margin: 0 0 24px 0;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
section.editor .rcol h1 .text {
  display: block;
  float: left;
  padding-left: 6px;
  padding-right: 6px;
  margin: 0 8px 16px 0;
}
section.editor .rcol h1 .text span {
  font-weight: 300;
  font-size: 18px;
  line-height: 2;
  color: #000000;
  margin-right: 24px;
}
section.editor .rcol h1 #block-title-input {
  height: 36px;
  border: 1px solid transparent;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
  position: relative;
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
}
section.editor .rcol h1 #block-title-input[contentEditable='true'] {
  -webkit-user-select: text !important;
  user-select: text !important;
}
section.editor .rcol h1 #block-title-input[contentEditable='true']:empty:before {
  content: attr(placeholder);
  color: #aaa;
}
section.editor .rcol h1 #block-title-input:hover {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.13);
  border-radius: 7px;
}
section.editor .rcol h1 #block-title-input:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  outline: none;
  border: solid 1px rgba(0, 0, 0, 0.33);
  border-radius: 7px;
  cursor: text;
  max-width: unset;
  overflow: unset;
}
section.editor .rcol h1 #block-title-input.no-editable {
  pointer-events: none;
}
section.editor .rcol h1 .h1-but {
  display: inline-block;
  margin-left: 26px;
  vertical-align: middle;
  height: 36px;
  align-self: flex-end;
}
section.editor .rcol h1 .h1-but > *,
section.editor .rcol h1 .h1-but .button {
  display: inline-block;
  width: 24px;
  height: 24px;
}
section.editor .rcol h1 .h1-but .button:hover {
  opacity: 1;
}
section.editor .rcol h1 .h1-but > div {
  position: relative;
}
section.editor .rcol h1 .h1-but > div .get-link.button {
  opacity: 0.3;
  background: url('../images/editor/ic_code.svg') center center no-repeat;
}
section.editor .rcol h1 .h1-but > div .get-link-popup {
  position: absolute;
  z-index: 100005;
  width: 252px;
  height: 96px;
  border-radius: 3px;
  background-color: #f3f2f2;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0);
  left: 0;
  top: -9999px;
  padding: 12px 16px;
  opacity: 0;
  transition: top 0ms 200ms, opacity 200ms 0ms;
  -webkit-transition: top 0ms 200ms, opacity 200ms 0ms;
}
section.editor .rcol h1 .h1-but > div .get-link-popup .fb-button {
  width: 145px;
  height: 24px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}
section.editor .rcol h1 .h1-but > div .get-link-popup input {
  width: 220px;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
  background-color: #ffffff;
  font-size: 16px;
  display: block;
  border: solid 1px #d9d9d9;
  padding: 0 12px;
}
section.editor .rcol h1 .h1-but > div .get-link-popup.act {
  transition: top 0ms 0ms, opacity 200ms 0ms;
  -webkit-transition: top 0ms 0ms, opacity 200ms 0ms;
  opacity: 1;
  top: 44px;
}
section.editor .rcol h1 .h1-but .remove.button {
  opacity: 0.3;
  background: url('../images/editor/ic_trash.svg') center center no-repeat;
}
section.editor .rcol h1 .h1-but .remove.button:hover {
  opacity: 1;
}
section.editor .rcol h1 .h1-but .copy.button {
  opacity: 0.3;
  background: url('../images/editor/ic_duplicate.svg') center center no-repeat;
}
section.editor .rcol h1 .h1-but .copy.button:hover {
  opacity: 1;
}
section.editor .rcol h1 .but-container {
  cursor: default;
}
section.editor .rcol h1 .but-container .top {
  top: -34px !important;
}
section.editor .rcol h1 .but-container .top .tooltip-inner {
  background-color: #053748;
}
section.editor .rcol h1 .but-container .top .tooltip-arrow {
  border-top-color: #053748;
}
section.editor .rcol h1 .but-container.remove {
  margin-left: 10px;
  flex: 1;
  text-align: right;
  cursor: default;
}
section.editor .rcol h1 .button.close {
  position: absolute;
  right: 0;
  top: 8px;
  width: 24px;
  height: 24px;
  background: url('../images/editor/ic-close-black.svg') center center no-repeat;
  background-size: cover;
  opacity: 0.3;
}
section.editor .rcol h1 .button.close:hover {
  opacity: 1;
}
section.editor .rcol h1 ul:not(.days-selector) {
  position: absolute;
  top: -99999px;
  opacity: 0;
  min-width: 112px;
  min-height: 32px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.02);
  padding: 0;
  margin: 0;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
}
section.editor .rcol h1 ul:not(.days-selector) li {
  display: block;
  padding: 0;
  margin: 0;
}
section.editor .rcol h1 ul:not(.days-selector) li a {
  display: block;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  padding: 0 0 0 16px;
  margin: 0;
  text-decoration: none;
  text-align: left;
  font-weight: 400;
}
section.editor .rcol h1 ul:not(.days-selector) li a:hover {
  text-decoration: underline;
}
section.editor .rcol h1 ul.act {
  top: 28px;
  right: 0;
  left: auto;
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
}
section.editor .rcol h1:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
section.editor .rcol .link-ref-on {
  display: flex;
  padding-bottom: 16px;
  cursor: default;
}
section.editor .rcol .link-ref-on .text {
  margin-bottom: 0;
}
section.editor .rcol .link-ref-on .text,
section.editor .rcol .link-ref-on .but-container,
section.editor .rcol .link-ref-on .ref-link {
  flex-shrink: 0;
}
section.editor .rcol h1:hover .button.edit {
  opacity: 0.3;
}
section.editor .rcol h1:hover .button.locked {
  opacity: 0.3;
}
section.editor .rcol .button-ver2.send-now {
  display: inline-block;
  margin-top: 32px;
  position: relative;
  padding-left: 54px;
}
section.editor .rcol .button-ver2.send-now:after {
  background: url('../images/editor/ic_send_white.svg') center center no-repeat;
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}
section.editor .rcol .description {
  margin-top: -20px;
}
@media (min-width: 1340px) {
  section.editor > div {
    max-width: inherit;
  }
  section.editor > div .lcol {
    width: 408px;
  }
  section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .tags-cell span,
  section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .tags-cell span,
  section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .tags-cell span {
    font-size: 13px;
    line-height: 32px;
  }
  section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .tags-cell span {
    font-size: 13px;
    line-height: 18px;
  }
  section.editor .rcol {
    padding: 32px 33px 0 444px;
  }
}
@media (min-width: 1460px) {
  section.editor > div {
    max-width: inherit;
  }
  section.editor > div .lcol {
    width: 528px;
  }
  section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .tags-cell span,
  section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .tags-cell span,
  section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .tags-cell span {
    font-size: 13px;
    line-height: 32px;
  }
  section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .tags-cell span {
    font-size: 13px;
    line-height: 18px;
  }
  section.editor .rcol {
    padding: 32px 33px 0 564px;
  }
}
.block-title-tooltip.bottom-left .tooltip-inner {
  background-color: #053748;
}
.block-title-tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: #053748 !important;
}
.broadcast-date__error-container {
  display: flex;
  align-items: center;
  color: #de614d;
  margin-top: 8px;
}
.broadcast-date__error-text {
  margin-left: 8px;
  font-size: 15px;
  line-height: 21px;
}
section.dashboard {
  margin-top: -120px;
}
section.dashboard > div {
  position: static;
}
section.dashboard > div h1 {
  position: relative;
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  margin: 0;
  padding: 32px 16px 0;
  -webkit-user-select: none;
  user-select: none;
}
section.dashboard > div h1 .button.refresh {
  position: absolute;
  right: 16px;
  top: 41px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  color: var(--black);
  padding: 0 0 0 32px;
}
section.dashboard > div h1 .button.refresh::after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  background: url('../images/dashboard/ic_refresh.svg') no-repeat center center;
  height: 24px;
  width: 24px;
  opacity: 0.2;
  transition: opacity 60ms;
  -webkit-transition: opacity 60ms;
}
section.dashboard > div h1 .button.refresh:hover::after {
  opacity: 1;
}
section.dashboard > div .bots-list {
  text-align: left;
  padding: 32px 0 0;
  margin-left: 16px;
  margin-right: 0;
  -webkit-user-select: none;
  user-select: none;
}
section.dashboard > div .bots-list .bot-item {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 62px 24px 0;
  width: 300px;
  height: 242px;
  border-radius: 18px;
  border: solid 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0 30px 32px 0;
  text-align: center;
}
section.dashboard > div .bots-list .bot-item .logo {
  width: 72px;
  height: 72px;
  background-color: #f7f7f7;
  border-radius: 50%;
  margin: 0 auto 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}
section.dashboard > div .bots-list .bot-item .title a {
  font-weight: 300;
  font-size: 24px;
  line-height: 1.3;
  color: var(--black);
  text-decoration: none;
}
section.dashboard > div .bots-list .bot-item .menu {
  position: absolute;
  top: 12px;
  right: 12px;
}
section.dashboard > div .bots-list .bot-item .menu > * {
  display: inline-block;
  vertical-align: top;
}
section.dashboard > div .bots-list .bot-item .menu .button.edit {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  background: url('../images/dashboard/ic-edit-black.svg') center center no-repeat;
  opacity: 0.3;
}
section.dashboard > div .bots-list .bot-item .menu .button.edit:hover {
  opacity: 1;
}
section.dashboard > div .bots-list .bot-item .menu ul {
  position: absolute;
  top: -99999px;
  opacity: 0;
  min-width: 112px;
  min-height: 32px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.02);
  padding: 0;
  margin: 0;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
}
section.dashboard > div .bots-list .bot-item .menu ul li {
  display: block;
  padding: 0;
  margin: 0;
}
section.dashboard > div .bots-list .bot-item .menu ul li a {
  display: block;
  font-size: 16px;
  line-height: 32px;
  color: var(--black);
  padding: 0 0 0 16px;
  margin: 0;
  text-decoration: none;
  text-align: left;
}
section.dashboard > div .bots-list .bot-item .menu ul li a:hover {
  text-decoration: underline;
}
section.dashboard > div .bots-list .bot-item .menu ul.act {
  top: 28px;
  right: 0;
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms, top 0ms cubic-bezier(0.22, 0.61, 0.36, 1) 0ms;
}
section.dashboard > div .bots-list .bot-item.add p {
  font-size: 15px;
  margin: 0;
}
section.dashboard > div .bots-list .bot-item.add p:first-child {
  margin-top: 10px;
}
section.dashboard > div .bots-list .bot-item.add .link {
  margin-top: 30px;
}
section.dashboard > div .bots-list .bot-item.add .link a {
  color: #448af1;
  text-decoration: none;
}
section.dashboard-v2 {
  box-sizing: content-box;
  min-height: 100%;
  margin-top: -104px;
  padding-bottom: 104px;
  position: relative;
  max-width: 992px;
  margin: -104px auto 0;
  padding-top: 104px;
}
section.dashboard-v2 > div {
  padding: 48px 16px 10px;
  position: static;
  box-sizing: border-box;
}
section.dashboard-v2 > div h1 {
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
  margin: 0 0 20px;
  padding: 0;
}
section.dashboard-v3 {
  box-sizing: content-box;
  min-height: 100%;
  margin-top: -104px;
  position: relative;
  padding-top: 104px;
  background-color: var(--greyLight20);
}
section.dashboard-v3 .bots-container {
  margin: 0;
  max-width: none;
  position: static;
}
section.dashboard-v3 .bots-container .bots-new-list {
  position: static;
  box-sizing: border-box;
  max-width: 1150px;
  margin: 0 auto;
  padding: 32px 16px 0;
}
@media (min-width: 1530px) {
  section.dashboard-v3 .bots-container .bots-new-list {
    max-width: 1530px;
  }
}
.min-h {
  min-height: 500px;
}
section.bot-create {
  margin-top: -84px;
  padding: 32px 32px 128px 32px;
}
section.bot-create > div {
  position: static;
  max-width: 978px;
  min-width: 978px;
}
section.bot-create > div h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
  padding: 0;
  margin: 20px 0 25px;
  text-align: left;
}
section.bot-create > div .bots-list .bot-item {
  margin: 0 36px 36px 0;
}
section.bot-create > div .bot-panel {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 40px 32px;
  width: 100%;
  height: 128px;
  background-color: var(--greyLight20);
}
section.bot-create > div .bot-panel .bot-panel-container {
  margin: 0 auto;
  width: 978px;
  font-size: 0;
}
section.bot-create > div .bot-panel .bot-name {
  display: inline-block;
  position: relative;
  height: auto;
  vertical-align: bottom;
}
section.bot-create > div .bot-panel .bot-name input {
  display: inline-block;
  width: 684px;
  height: 48px;
  line-height: 43px;
  padding: 0 12px;
  margin: 0 32px 0 0;
  font-weight: 300;
  font-size: 32px;
  color: var(--black);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffefed;
  border: solid 2px #e9604a;
}
section.bot-create > div .bot-panel .bot-name input::-webkit-input-placeholder {
  color: #e9604a;
}
section.bot-create > div .bot-panel .bot-name input::-moz-placeholder {
  color: #e9604a;
}
section.bot-create > div .bot-panel .bot-name input:-moz-placeholder {
  color: #e9604a;
}
section.bot-create > div .bot-panel .bot-name input:-ms-input-placeholder {
  color: #e9604a;
}
section.bot-create > div .bot-panel .bot-name input:-o-input-placeholder {
  color: #e9604a;
}
section.bot-create > div .bot-panel .bot-name input:input-placeholder {
  color: #e9604a;
}
section.bot-create > div .bot-panel .bot-name input:focus {
  background-color: var(--white);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.13);
}
section.bot-create > div .bot-panel .bot-name input:focus::-webkit-input-placeholder {
  color: var(--grey);
}
section.bot-create > div .bot-panel .bot-name input:focus::-moz-placeholder {
  color: var(--grey);
}
section.bot-create > div .bot-panel .bot-name input:focus:-moz-placeholder {
  color: var(--grey);
}
section.bot-create > div .bot-panel .bot-name input:focus:-ms-input-placeholder {
  color: var(--grey);
}
section.bot-create > div .bot-panel .bot-name input:focus:-o-input-placeholder {
  color: var(--grey);
}
section.bot-create > div .bot-panel .bot-name input:focus:input-placeholder {
  color: var(--grey);
}
section.bot-create > div .bot-panel .bot-name input.valid {
  background-color: var(--white);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.13);
}
section.bot-create > div .bot-panel .bot-name div {
  font-size: 14px;
  line-height: 1.4;
  color: #807e7e;
}
section.bot-create > div .bot-panel .button {
  position: relative;
  display: inline-block;
  margin: 0;
  width: 256px;
  height: 48px;
  vertical-align: bottom;
  border-radius: 3px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 48px;
  color: var(--white);
}
section.bot-create > div .bot-panel .button[disabled] {
  opacity: 0.6;
  background-color: #e9604a;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
}
section.bot-create > div .bot-panel .button.loading {
  pointer-events: none;
  cursor: default;
}
section.bot-create > div .bot-panel .button.loading > .pre-loader {
  display: block;
}
section.bot-create > div .bot-panel .button.loading > .title {
  display: none;
}
section.bot-create > div .bot-panel .button > .pre-loader {
  display: none;
  position: absolute;
  width: 100%;
  height: 32px;
  margin: 8px 0;
}
section.bot-create > div .bot-panel .button > .pre-loader::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 3;
  background: url('../images/bot-create/spinner_w.svg') no-repeat center center;
  background-size: 32px 32px;
}
section.preferences > div {
  padding: 48px 16px 120px;
}
section.preferences > div h2 {
  font-weight: 300;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
  padding: 0;
  margin: 0 0 8px;
  text-align: left;
}
section.preferences > div p {
  font-size: 16px;
  line-height: 1.5;
  color: #9b9b9b;
  margin: 0 0 20px;
}
section.preferences > div form {
  border-radius: 18px;
  background-color: var(--greyLight20);
  padding: 32px;
  margin: 0 0 72px;
}
section.preferences > div form select,
section.preferences > div form input {
  display: block;
  width: 360px;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px #d9d9d9;
  padding: 0 12px;
  font-size: 16px;
  color: var(--black);
  margin: 0 0 24px;
}
section.preferences > div form input {
  width: 100%;
}
section.preferences > div form label {
  font-size: 16px;
  line-height: 1.5;
  color: #9b9b9b;
}
section.preferences > div form label input {
  margin: 12px 0 24px;
}
section.preferences > div > .button {
  margin: 32px 0 0;
}
.hide-pro-preloader {
  opacity: 0;
  pointer-events: none;
}
.structure-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 36px;
  width: 36px;
  background: transparent url('../images/content-preloader/spinner_b.svg') no-repeat 50% 50%;
  background-size: 36px;
  content: '';
  z-index: -100;
  transition: opacity 0.2s linear 0.5s;
  opacity: 0;
}
.structure-preloader-active {
  opacity: 1;
  z-index: 10000;
}
.ai-setup-preloader::after {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 36px;
  width: 36px;
  margin-left: 80px;
  background: transparent url('../images/content-preloader/spinner_b.svg') no-repeat 50% 50%;
  background-size: 36px;
  content: '';
  z-index: 1000;
  transition: opacity 0.2s linear 0.5s;
  opacity: 1;
}
.promotion-content-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 36px;
  width: 36px;
  margin-left: 80px;
  background: transparent url('../images/content-preloader/spinner_b.svg') no-repeat 50% 50%;
  background-size: 36px;
  z-index: 1000;
  transition: opacity 0.2s linear 0.5s;
  opacity: 1;
  min-width: 0;
  max-width: none !important;
}
.promotion-preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 36px;
  width: 36px;
  margin-left: 80px;
  background: transparent url('../images/content-preloader/spinner_b.svg') no-repeat 50% 50%;
  background-size: 36px;
  z-index: 1000;
  transition: opacity 0.2s linear 0.5s;
  opacity: 1;
  min-width: 0;
  max-width: none;
}
.promotion-preloader::before {
  background: transparent !important;
}
@media (max-width: 1024px) {
  .ai-setup-preloader::after {
    margin-left: 40px;
  }
  .promotion-content-preloader {
    margin-left: 40px;
  }
  .promotion-preloader {
    margin-left: 40px;
  }
}
section.bot-spinner {
  height: 100%;
  width: 100%;
  margin-top: 100px;
}
.redesign {
  --sidebar-width: 408px;
  --content-padding-left: 44px;
}
@media (min-width: 1460px) {
  .redesign {
    --sidebar-width: 502px;
  }
}
.redesign .button-ver2.white-rounded {
  border-radius: 12px;
  border: 1px solid #dfdfdf;
}
.redesign section.bot-selector {
  height: 64px;
  background-color: var(--white);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  border: none;
}
.redesign section.bot-selector > div nav {
  width: 100px;
  background-color: var(--greyLight20);
  padding-top: 24px;
  overflow: visible;
}
.redesign section.editor {
  padding-left: 0;
  background-color: var(--greyLight30);
}
.redesign section.bot-selector > div nav ul {
  width: 64px;
  margin-left: 32px;
}
.redesign section.bot-selector > div nav ul li {
  height: 52px;
  margin-bottom: 10px;
}
.redesign section.bot-selector > div nav ul li > a {
  height: 52px;
  font-size: 11px;
  font-weight: 600;
  line-height: 2.18;
  text-align: center;
  color: var(--greyDark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  background-image: none;
  transition: none;
}
.redesign section.bot-selector > div nav ul li > a svg {
  stroke: var(--greyDark);
  margin-top: 10px;
  margin-left: inherit;
  margin-right: inherit;
}
.redesign section.bot-selector > div nav ul li > a img {
  padding: 0;
  width: auto;
  height: auto;
  vertical-align: top;
  margin-top: 10px;
}
.redesign section.bot-selector > div nav ul li > a .list-title {
  margin-bottom: 2px;
}
.redesign section.bot-selector > div nav ul li a.act {
  height: 52px;
  border-radius: 8px;
  background-color: var(--white);
}
.redesign section.editor > div .lcol {
  box-shadow: none;
  background-color: var(--greyLight20);
  width: calc(var(--sidebar-width) - 18px);
  padding-left: 28px;
}
.redesign .aside {
  padding-top: 1px;
}
.redesign .aside .groups-list .group-item {
  border: 0;
}
.redesign .aside .groups-list .group-item:hover {
  border: 0;
  background-color: inherit;
  box-shadow: none;
}
.redesign .aside .groups-list .group-item .blocks-list .block-item {
  margin-bottom: 4px;
  font-size: 13px;
  display: inline-block;
}
.redesign .aside .groups-list .group-item .blocks-list .block-item span {
  color: inherit !important;
  padding: 0;
}
.redesign section.bot-selector > div nav ul .bottom-list {
  bottom: 90px;
}
.redesign section.bot-selector > div nav ul li > a .ava {
  margin: 8px 0 0;
}
.redesign:not(.flat-aside) .aside .groups-list .group-item .blocks-list .block-item:active:not(.add) span {
  color: var(--black) !important;
}
.redesign .aside .header {
  margin-left: 0;
}
.redesign .card-item.text .card {
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 1px 0 0 rgba(0, 0, 0, 0.08);
}
.redesign .card-item .card {
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 1px 0 0 rgba(0, 0, 0, 0.08);
  border-color: transparent;
}
.redesign .aside .groups-list .group-item {
  padding-left: 0;
  position: relative;
}
.redesign .aside .groups-list > .add {
  margin-left: 0;
}
.redesign .aside .groups-list .group-item .blocks-list .block-item span {
  font-size: 13px;
  line-height: 16px;
}
@media (min-width: 1340px) {
  .redesign .aside .groups-list .group-item .blocks-list .block-item {
    width: 110px;
  }
}
.redesign section.editor .rcol h1 .text {
  font-size: 18px;
  color: var(--black);
  line-height: 32px;
}
.redesign section.editor .rcol {
  padding-left: var(--content-padding-left);
}
@media (max-width: 1024px) {
  .redesign section.bot-selector > div .list-title {
    display: block;
  }
  .redesign section.editor {
    padding-left: 0;
  }
  .redesign section.bot-selector > div .logo {
    width: 117px;
  }
  .redesign .aside .groups-list .group-item .blocks-list .block-item.add > span {
    display: inline-block;
  }
  .redesign section.settings {
    padding-left: 0 !important;
  }
  .redesign section.ai-setup {
    padding-left: 120px;
  }
}
.redesign .aside .groups-list .group-item .blocks-list .block-item.add {
  background-color: var(--greyLight20);
  border: 1px solid #dcdcdc;
  font-size: 15px;
}
.redesign section.ai-setup .box {
  margin: 0;
}
.redesign section.ai-setup {
  padding-left: 120px !important;
  padding-right: 40px !important;
}
.redesign section.users-tab .box {
  margin: 0;
  padding: 0;
}
.redesign section.users-tab {
  padding-left: 32px !important;
  padding-right: 40px !important;
}
.redesign section.bot-selector > div nav ul li.update-present:after {
  content: '';
  width: 8px;
  height: 8px;
  padding: 0;
  right: 17px;
  top: 3px;
  transform: translateY(0%);
  border: solid 2px var(--greyLight20);
  box-sizing: content-box;
  border-radius: 100%;
}
.redesign section.ai-setup .box .content .rules .intents-list .intent-item {
  border: none;
}
.redesign section.settings {
  padding-left: 0;
}
.redesign section.settings > .rcol .border-box {
  box-shadow: none;
  border: none;
}
.redesign section.settings .rcol .border-box.connect-to-page .button {
  border-radius: 12px;
  box-shadow: none;
}
.redesign section.settings .rcol .border-box.connect-to-page .button:hover,
.redesign .button-ver2.white:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border-top-color: rgba(0, 0, 0, 0.17);
  border-left-color: rgba(0, 0, 0, 0.17);
  border-right-color: rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.redesign .button-ver2.white,
.button-ver2.white-rounded {
  background-color: var(--white);
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign .menu-local .menu-local-box .menu-local-item {
  height: 36px;
  line-height: 36px;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 100px;
}
.redesign .menu-local .menu-local-box .menu-local-item_visible {
  background-color: rgba(255, 254, 254, 0.8);
  border: 1px solid rgba(16, 16, 16, 0.08);
  color: var(--black);
  height: 36px;
  line-height: 33px;
  text-transform: capitalize;
  box-shadow: none;
}
.redesign .menu-local .menu-local-box .menu-local-item.add {
  text-transform: uppercase;
}
.redesign .menu-local .menu-local-box .menu-local-item_visible:hover {
  background-color: #f1f1f1;
  border: 1px solid rgba(16, 16, 16, 0.18);
}
.redesign .menu-local .menu-local-box .menu-local-item_visible:focus {
  background-color: #f8f8f8;
  border: 1px solid #916a7c;
}
.redesign .menu-local .menu-local-box .menu-local-item_visible:hover:active,
.redesign .menu-local .menu-local-box .menu-local-item_visible:active {
  background-color: #e1dbda;
  border: 1px solid rgba(16, 16, 16, 0.16);
}
.redesign .menu-local .menu-local-box .menu-local-item.add .but::before {
  top: 9px;
}
.redesign .selector-ver2 {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign section.ai-setup .box .content .groups-list .group-item.add {
  border: 1px solid #ebebeb !important;
  box-shadow: none !important;
}
.redesign section.ai-setup .box .content .groups-list .group-item span {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign section.bot-selector > div .button-box .button.connect {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign faq .faq-container {
  width: 44px;
  height: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.13);
  border-radius: 16px;
  z-index: 999999;
}
.redesign faq .faq-container .faq.bt {
  border-radius: 16px;
  width: 44px;
  height: 40px;
  background-color: #053748;
}
.redesign faq .faq-container .bt.faq.presed {
  background: #053748;
}
.redesign faq .faq-container .faq.bt:hover {
  background-color: #053748;
}
.redesign faq .faq-container .bt.faq:after {
  background-image: url('../../assets2/images/ic_faq.svg');
  width: 22px;
  height: 22px;
  left: 11px;
  top: 9px;
}
.redesign .chat-ext-box {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign .grow-item {
  display: flex;
}
.grow-item__link {
  color: var(--blue);
  text-decoration: none;
}
.redesign .button-ver2.bc-temp-white {
  box-shadow: none;
  border: 1px solid #ebebeb;
}
.redesign .button-ver2.bc-temp-white.act {
  background-color: var(--white) !important;
  border: solid 2px rgba(5, 55, 72, 0.4) !important;
  color: #053748 !important;
  box-shadow: none;
}
body.redesign header > div .logo-mini {
  width: 44px;
  height: 40px;
  border-radius: 16px;
  display: block;
  top: 12px;
  left: 42px;
}
body.redesign header > div .logo-mini img {
  width: 24px;
  height: 24px;
  position: relative;
  left: 10px;
  top: 8px;
}
body.redesign header > div .logo-mini img:first-child {
  display: none;
}
body.redesign header > div .logo-mini img.logo-mini_redesign {
  display: block !important;
}
.redesign section.dashboard-v3 .bots-list .bot-item {
  box-shadow: none;
  border: 1px solid #ebebeb;
}
.redesign section.dashboard-v3 .bots-list .bot-item:not(.edit):hover {
  box-shadow: none;
}
.redesign .tos .window {
  box-shadow: none;
  border: 1px solid #ebebeb;
}
.redesign .tos .extended-tos-window .border-box.connect-to-page .state.state-pages-list .create-page-block {
  box-shadow: none;
  border: 1px solid #ebebeb;
}
.redesign .tos .extended-tos-window .border-box.connect-to-page .button {
  color: var(--white) !important;
  background-color: #f25f38;
  border-radius: 7px;
  border: none !important;
}
body.redesign > .popup .window .footer .button {
  border-radius: 12px;
}
.redesign section.editor > div .lcol .post-sender-scheduler {
  box-shadow: none;
  background-color: var(--greyLight20);
}
.redesign section.editor > div .lcol .post-sender-scheduler .send-now,
.redesign section.editor > div .lcol .post-sender-scheduler > * {
  padding-left: 0;
}
.redesign section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags span,
.redesign section.editor > div .lcol .post-sender-scheduler .autopost-group .tags span,
.redesign section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags span,
.redesign section.editor > div .lcol .post-sender-scheduler .sequence-events .tags span {
  background-color: var(--white);
  border: solid 2px transparent;
  margin-left: 3px;
}
.redesign section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags span:hover,
.redesign section.editor > div .lcol .post-sender-scheduler .autopost-group .tags span:hover,
.redesign section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags span:hover,
.redesign section.editor > div .lcol .post-sender-scheduler .sequence-events .tags span:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.13);
}
.redesign section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags span.act,
.redesign section.editor > div .lcol .post-sender-scheduler .autopost-group .tags span.act,
.redesign section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags span.act,
.redesign section.editor > div .lcol .post-sender-scheduler .sequence-events .tags span.act {
  border-radius: 8px;
  background-color: var(--white) !important;
  border: solid 2px rgba(5, 55, 72, 0.4) !important;
  color: #053748 !important;
  box-shadow: none;
}
.redesign .button-ver2.bc-temp-white:active:not(.add) {
  background-color: var(--white) !important;
  border: solid 2px transparent !important;
  color: var(--black) !important;
}
.redesign .art-big-dropdown .selected {
  box-shadow: none;
  border: 1px solid #ebebeb;
}
.redesign .cf-input {
  box-shadow: none !important;
  border: 1px solid #ebebeb !important;
}
.redesign .chat-ext-box__extensions-dropdown .extensions-dropdown__input input {
  box-shadow: none !important;
  border: 1px solid #ebebeb !important;
}
.redesign section.settings > .rcol .border-box.timezone select {
  border: 1px solid #ebebeb !important;
}
.redesign section.settings .rcol .border-box.bc-api .token-wrapper .token-field {
  border: 1px solid #ebebeb !important;
  box-shadow: none !important;
}
.redesign section.ai-setup .box .content .rules .intents-list .intent-item {
  border: 1px solid #ebebeb !important;
  box-shadow: none !important;
}
.redesign section.ai-setup .box .content .rules .intents-list .intent-item::before {
  background-color: #ebebeb;
}
.redesign section.statistics-container {
  padding-left: 0 !important;
}
.redesign .statistics .statistics-main .panel {
  border: 1px solid #ebebeb !important;
  box-shadow: none !important;
}
.redesign section.editor > div .lcol .post-sender-scheduler .send-now .button-ver2.add.act::before {
  background: url(../../assets2/images/editor/ic_add_small_new.svg) no-repeat center center !important;
  opacity: 0.3;
}
.redesign section.ai-setup .box .content .groups-list .group-item.add {
  height: 30px;
  border: solid 2px transparent;
}
.redesign section.ai-setup .box .content .groups-list .group-item span {
  height: 30px;
  border: solid 2px transparent;
  line-height: 26px;
  box-sizing: border-box;
  transition: none;
}
.redesign section.ai-setup .box .content .groups-list .group-item.act span {
  background-color: var(--white) !important;
  border: solid 2px rgba(5, 55, 72, 0.4) !important;
  color: #053748 !important;
  box-shadow: none;
}
.redesign section.editor > div .lcol .block-error.block-disabled::after {
  background-color: #e9604a;
  border-color: var(--white);
}
.redesign .aside .groups-list .group-item .blocks-list .block-item.error::after {
  border-color: var(--white);
}
.redesign .popup.plugin-browser .scroll-box .window .content > ul > li {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign .segmentation {
  background-color: #fdfdfd;
  border: none;
}
.redesign .selector-ver2 .popup {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign div.plugin-modules .card .content .control input,
.redesign div.plugin-modules .card .content .control .input {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign div.plugin-modules .card .content .control .selector {
  border: 1px solid #ebebeb;
  box-shadow: none;
}
.redesign .card-item.text .card .content .body textarea:focus,
.redesign .card-item.text .card .content .body .wrap-mask:focus {
  border: 1px solid #ebebeb;
}
.redesign .menu-local .menu-local-box .menu-local-item.act {
  border: none;
  background-color: #f4faff;
  border: 1px solid #d0e9ff;
  color: var(--blue);
}
.redesign .menu-local .menu-local-box .menu-local-item.act:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  background-color: #e7f3ff;
}
.redesign .menu-local .menu-local-box .menu-local-item.act:hover:active,
.redesign .menu-local .menu-local-box .menu-local-item.act:active {
  box-shadow: inset 0px 1px 0px rgba(16, 16, 16, 0.16);
  background: linear-gradient(0deg, rgba(16, 16, 16, 0.08), rgba(16, 16, 16, 0.08)), #e3f2ff;
}
.redesign .statistics {
  -ms-overflow-style: none;
}
.statistics::-webkit-scrollbar {
  display: none;
}
.redesign .aside .groups-list .group-item .blocks-list .block-item span.big-text {
  font-size: 13px;
  line-height: 16px;
}
.redesign .aside .groups-list .group-item .blocks-list .block-item.add > span {
  display: inline-block;
}
.redesign .aside .groups-list .group-item .group-title .button-ver2.trash-mid,
.redesign .aside .groups-list .group-item .group-title .button-ver2.lock {
  right: -22px;
  left: auto;
}
.redesign .grow-item__title {
  font-size: 24px;
  font-weight: 300;
}
.redesign .grow-item__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
}
.redesign .grow-item__description.grow-item-description-wide {
  width: 448px;
}
.redesign .grow-list__item {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  color: #767676;
}
.redesign .grow-item__description_fix-width {
  width: 350px;
}
.redesign .grow-item__button:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.13);
  border-top-color: rgba(0, 0, 0, 0.13);
  border-left-color: rgba(0, 0, 0, 0.13);
  border-right-color: rgba(0, 0, 0, 0.13);
  border-bottom-color: rgba(0, 0, 0, 0.17);
}
.redesign .aside .groups-list .group-item .blocks-list {
  font-size: 0;
}
html {
  background-color: var(--greyLight20) !important;
}
.redesign .art-baloons-input {
  box-shadow: none;
  border: 1px solid #ebebeb;
}
.redesign section.editor > div .lcol .post-sender-scheduler .broadcast-date .tags .tags-cell,
.redesign section.editor > div .lcol .post-sender-scheduler .autopost-group .tags .tags-cell,
.redesign section.editor > div .lcol .post-sender-scheduler .recurrent-events .tags .tags-cell,
.redesign section.editor > div .lcol .post-sender-scheduler .sequence-events .tags .tags-cell {
  padding: 2px;
}
.redesign .quick-reply-plugin {
  background-color: transparent;
}
.redesign .quick-reply-plugin::before,
.redesign .quick-reply-plugin::after {
  background-color: transparent;
}
.redesign faq .faq-container .bt.community,
.redesign faq .faq-container .bt.email {
  width: 44px;
  height: 44px;
}
.redesign faq .faq-container .bt {
  right: 0;
}
.redesign section.editor > div .lcol .post-sender-scheduler .send-now {
  padding-right: 40px;
}
.redesign section.editor > div .lcol .post-sender-scheduler > * {
  padding-right: 44px;
}
.redesign .aside .groups-list .group-item.ui-sortable-helper:active {
  background-color: var(--greyLight20);
  box-shadow: 0px 5px 16px -1px rgba(0, 0, 0, 0.13);
}
/*------------------------------------------------------------------------------
  tab buttons start
------------------------------------------------------------------------------*/
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act,
.redesign .button-popup-editor .hide-box .state-selector li.act {
  border-bottom: 2px solid #053748;
}
.redesign .action-item-v2 .content-box .hide-box .state-selector li:hover,
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act,
.redesign .button-popup-editor .hide-box .state-selector li:hover,
.redesign .button-popup-editor .hide-box .state-selector li.act {
  color: #053748;
}
.redesign .action-item-v2 .content-box .hide-box .state-selector li:hover.blocks::before,
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act.blocks::before,
.redesign .button-popup-editor .hide-box .state-selector li:hover.blocks::before,
.redesign .button-popup-editor .hide-box .state-selector li.act.blocks::before {
  background-image: url('../images/redesign/redesign-ic_blocks_small_active.svg');
}
.redesign .action-item-v2 .content-box .hide-box .state-selector li:hover.url::before,
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act.url::before,
.redesign .button-popup-editor .hide-box .state-selector li:hover.url::before,
.redesign .button-popup-editor .hide-box .state-selector li.act.url::before {
  background-image: url('../images/redesign/redesign-ic_link_small_active.svg');
}
.redesign .action-item-v2 .content-box .hide-box .state-selector li:hover.phone-call::before,
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act.phone-call::before,
.redesign .button-popup-editor .hide-box .state-selector li:hover.phone-call::before,
.redesign .button-popup-editor .hide-box .state-selector li.act.phone-call::before {
  background-image: url('../images/redesign/redesign-ic_call_small_active.svg');
}
.redesign .action-item-v2 .content-box .hide-box .state-selector li:hover.share::before,
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act.share::before {
  background-image: url('../images/redesign/redesign-ic_share_small_active.svg');
}
.redesign .action-item-v2 .content-box .hide-box .state-selector li:hover.buy::before,
.redesign .action-item-v2 .content-box .hide-box .state-selector li.act.buy::before,
.redesign .buy.act:before {
  background-image: url('../images/redesign/redesign-ic_buy_small_active.svg');
}
.redesign .button-popup-editor .hide-box .state-selector li:hover.submenu::before,
.redesign .button-popup-editor .hide-box .state-selector li.act.submenu::before {
  background-image: url('../images/redesign/redesign-ic_submenu_active.svg');
}
/* tab buttons end */
@media screen and (min-width: 1460px) {
  .redesign .aside .groups-list .group-item .group-title .button-ver2.trash-mid,
  .redesign .aside .groups-list .group-item .group-title .button-ver2.lock {
    right: -18px;
  }
}
.material-button {
  min-width: 100px;
  color: var(--greyDark);
  font-size: 13px;
  line-height: 34px;
  text-align: center;
  background-color: var(--greyLight20);
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: box-shadow 80ms ease, border-color 80ms ease, background-color 80ms ease;
}
.material-button:hover {
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17);
  border-top-color: rgba(0, 0, 0, 0.17);
  border-left-color: rgba(0, 0, 0, 0.17);
  border-right-color: rgba(0, 0, 0, 0.17);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.material-button:active {
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-left-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.22);
  transition: box-shadow 20ms ease, border-color 20ms ease;
}
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
.red::before {
  color: red !important;
}
.transparent {
  background-color: rgba(0, 0, 0, 0);
}
.orange {
  background-color: orange;
}
.cf-tile-margin {
  margin: 8px;
}
add-icon-big {
  fill: var(--grey);
  width: 40px;
  transition: fill 240ms ease;
}
.change-color-on-hover:hover .sub-text-animation {
  transition: color 240ms ease;
  color: var(--blue);
}
.change-color-on-hover:hover add-icon-big {
  fill: var(--blue);
}
